//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
//@ts-ignore
import qs from 'qs';
import type {
  DeleteUsagerQueryParams,
  AboutModel,
  DestinatairesModel,
  FormulaireBaseModel,
  GlobalVariables,
  ReferenceModel,
  ResponsableModel,
  SuiviModel,
  UsagerModel,
  UsagerRoleModel,
  UsagersModel
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

export const deleteUsager = (
  queryParams?: DeleteUsagerQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteUsager.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteUsager.key = '/api/Usager';

export const getAideAbout = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AboutModel>> => {
  return Http.getRequest(
    getAideAbout.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getAideAbout.key = '/api/aide/About';

export const getCommunCurrentuser = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ [x: string]: UsagerRoleModel }>> => {
  return Http.getRequest(
    getCommunCurrentuser.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCommunCurrentuser.key = '/api/Commun/currentuser';

export const getCommunEmptycache = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string>> => {
  return Http.getRequest(
    getCommunEmptycache.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCommunEmptycache.key = '/api/Commun/emptycache';

export const getCommunResponsables = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ [x: string]: ResponsableModel }>> => {
  return Http.getRequest(
    getCommunResponsables.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCommunResponsables.key = '/api/Commun/responsables';

export const getConfigurations = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GlobalVariables>> => {
  return Http.getRequest(
    getConfigurations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConfigurations.key = '/api/Configurations';

export const getConfigurationsMigrate = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GlobalVariables>> => {
  return Http.getRequest(
    getConfigurationsMigrate.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConfigurationsMigrate.key = '/api/Configurations/migrate';

export const getDestinataires = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DestinatairesModel>> => {
  return Http.getRequest(
    getDestinataires.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDestinataires.key = '/api/Destinataires';

export const getHealthz = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GlobalVariables>> => {
  return Http.getRequest(
    getHealthz.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getHealthz.key = '/api/healthz';

export const getReadyz = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GlobalVariables>> => {
  return Http.getRequest(
    getReadyz.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getReadyz.key = '/api/readyz';

export const getReferenceConsultationReferenceId = (
  referenceId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FormulaireBaseModel>> => {
  return Http.getRequest(
    template(getReferenceConsultationReferenceId.key, { referenceId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getReferenceConsultationReferenceId.key = '/api/Reference/consultation/{referenceId}';

export const getReferenceType = (
  type: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FormulaireBaseModel>> => {
  return Http.getRequest(
    template(getReferenceType.key, { type }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getReferenceType.key = '/api/Reference/{type}';

export const getSuivi = (
  requestBody: string[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SuiviModel>> => {
  return Http.getRequest(
    getSuivi.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSuivi.key = '/api/Suivi';

export const getSuiviAllCle = (
  cle: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReferenceModel>> => {
  return Http.getRequest(
    template(getSuiviAllCle.key, { cle }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSuiviAllCle.key = '/api/Suivi/all/{cle}';

export const getSuiviAssigneReferenceToConnectedUserReferenceId = (
  referenceId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getSuiviAssigneReferenceToConnectedUserReferenceId.key, { referenceId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getSuiviAssigneReferenceToConnectedUserReferenceId.key =
  '/api/Suivi/assigneReferenceToConnectedUser/{referenceId}';

export const getSuiviChangeAssignationReferenceIdCourtierId = (
  referenceId: number,
  courtierId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReferenceModel>> => {
  return Http.getRequest(
    template(getSuiviChangeAssignationReferenceIdCourtierId.key, { referenceId, courtierId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSuiviChangeAssignationReferenceIdCourtierId.key =
  '/api/Suivi/changeAssignation/{referenceId}/{courtierId}';

export const getSuiviChangeStatutReferenceIdStatutId = (
  referenceId: number,
  statutId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReferenceModel>> => {
  return Http.getRequest(
    template(getSuiviChangeStatutReferenceIdStatutId.key, { referenceId, statutId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSuiviChangeStatutReferenceIdStatutId.key = '/api/Suivi/changeStatut/{referenceId}/{statutId}';

export const getSuiviCourtierCodeFormulaire = (
  codeFormulaire: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UsagerModel[]>> => {
  return Http.getRequest(
    template(getSuiviCourtierCodeFormulaire.key, { codeFormulaire }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSuiviCourtierCodeFormulaire.key = '/api/Suivi/courtier/{codeFormulaire}';

export const getSuiviSingleReferenceId = (
  referenceId: number,
  requestBody: string[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SuiviModel>> => {
  return Http.getRequest(
    template(getSuiviSingleReferenceId.key, { referenceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSuiviSingleReferenceId.key = '/api/Suivi/single/{referenceId}';

export const getUsager = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UsagersModel>> => {
  return Http.getRequest(
    getUsager.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUsager.key = '/api/Usager';

export const getUsagerAfterPageSize = (
  pageSize: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UsagerModel[]>> => {
  return Http.getRequest(
    template(getUsagerAfterPageSize.key, { pageSize }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUsagerAfterPageSize.key = '/api/Usager/after/{pageSize}';

export const getUsagerPageIndexPageSize = (
  pageIndex: number,
  pageSize: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UsagersModel>> => {
  return Http.getRequest(
    template(getUsagerPageIndexPageSize.key, { pageIndex, pageSize }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUsagerPageIndexPageSize.key = '/api/Usager/{pageIndex}/{pageSize}';

export const postReference = (
  requestBody: FormulaireBaseModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FormulaireBaseModel>> => {
  return Http.postRequest(
    postReference.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postReference.key = '/api/Reference';

export const postSuivi = (
  requestBody: string[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SuiviModel>> => {
  return Http.postRequest(
    postSuivi.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postSuivi.key = '/api/Suivi';

export const postUsager = (
  requestBody: UsagerModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UsagersModel>> => {
  return Http.postRequest(
    postUsager.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUsager.key = '/api/Usager';

export const putDestinataires = (
  requestBody: DestinatairesModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DestinatairesModel>> => {
  return Http.putRequest(
    putDestinataires.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putDestinataires.key = '/api/Destinataires';

export const putSuivi = (
  requestBody: ReferenceModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReferenceModel>> => {
  return Http.putRequest(
    putSuivi.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putSuivi.key = '/api/Suivi';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain'
  }
};
export const _CONSTANT1 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};
