import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './AuthConfig';

export const getCurrentAccount = (): AccountInfo | undefined => {
  const msal = new PublicClientApplication(msalConfig);
  const accounts = msal.getAllAccounts();
  const configString = sessionStorage.globalConfig;
  if (configString != undefined && configString != '') {
    const config = JSON.parse(configString);
    return (accounts ?? []).find((a) => a.idTokenClaims?.aud == config.clientId);
  }
  return undefined;
};
