import { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { postUsager } from "../../services/services";
import { UsagerModel, UsagersModel } from "../../services/types";
import ValidationField, {  usagerValidations } from "../../services/validations";
import { clone } from "../../utils/cloneUtils";
import { createSetError } from "../../utils/errorsUtils";
import { getOptions } from "../../utils/optionsUtils";
import { applyFilter, sortUsagers } from "./gestionUsagersUI";
import { ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorMessage } from "@hookform/error-message";
import { getCurrentAccount } from "../../services/Authentification/AuthFunction";
import { resetCurrentUsager } from "../../utils/communUtils";

export default function DetailUsagerUI(props: {
    model: UsagersModel,
    setModel: (model: UsagersModel) => void,
    search: (text: string | undefined) => void,
    showToast: (message: string, options: ToastOptions, closeOnly?: boolean) => void
}) {
    // Membres
    if (!props.model || !props.model.usagerSelectionne)
        return <></>

    const usager = props.model.usagerSelectionne;

    // Use
    const { register, reset, handleSubmit, clearErrors, setError, formState: { errors, isDirty } } = useForm<UsagerModel>();
    const setFallbackError = createSetError();

    // State
    const [showConfirmation, setShowConfirmation] = useState(false);

    // Méthodes
    const save: SubmitHandler<UsagerModel> = async data => {

        const model = props.model;
        const isNew = data.id === 0;
        data.isDisabled = true;
        model.usagerSelectionne = data;

        props.showToast('Sauvegarde en cours', { type: 'info', autoClose: false });
        props.setModel(clone(model));

        const texteFiltre = model.texteFiltre;
        model.usagerSelectionne = data;
        postUsager(data)
            .then(data => { afterPost(data, texteFiltre, isNew) })
            .catch(setFallbackError);
    };

    const afterPost = (data: UsagersModel, texteFiltre: string | undefined, isNew: boolean) => {

        const model = props.model;
        data.usagerSelectionne!.isDisabled = false;
        let isError = false;

        if (data.usagerSelectionne?.functionalErrors && data.usagerSelectionne?.functionalErrors.length > 0) {
            model.showActiveUsager = true;
            model.usagerSelectionne = data.usagerSelectionne;
            isError = true;
            props.showToast('', {}, true);
            props.setModel(clone(model));
        }
        else {
            props.showToast('Succès', { type: 'success', autoClose: 1000 });
            model.showActiveUsager = false;
        }

        if (isError) {
            props.setModel(clone(model));
            setTimeout(() => {
                setError('courriel', { type: 'custom', message: data.usagerSelectionne?.functionalErrors![0] });
            }, 50);
        }
        else {
            model.usagers = addOrUpdateUsager(model.usagers!, data.usagerSelectionne!, isNew);
            model.usagersFiltered = applyFilter(texteFiltre, model.usagers!)
            model.usagersFiltered = sortUsagers(model.sortField!, model.usagersFiltered);
            model.superviseurs = data.superviseurs;
            props.setModel(clone(model));
        }
    }

    const addOrUpdateUsager = (usagers: UsagerModel[], data: UsagerModel, isNew: boolean) => {
        if (isNew) {
            usagers!.push(data);
        }
        else {
            const usager = usagers!.find(u => u.id === data.id)
            const index = usager && usagers!.indexOf(usager);
            if (index != undefined && index >= 0) {
                usagers![index] = data;
                if (data.courriel === getCurrentAccount()?.username)
                    resetCurrentUsager();
            }
            else //Ne devrait pas arrivé...
                usagers!.push(data);
        }
        return usagers;
    }

    useEffect(() => {
            
        setShowConfirmation(false);
        reset(usager);
    }, [props.model]);

    const askCloseConfirmation = () => {
        if (isDirty) {
            setShowConfirmation(true);
        }
        else {
            props.model.showActiveUsager = false;
            props.setModel(clone(props.model));
        }
    }

    const closeCurrentWindow = (model?: UsagersModel) => {

        const m = model ?? props.model;
        m.showActiveUsager = false;
        m.showCourrielPermis = false;
        if (model)
            props.setModel(clone(m));
    }

    const getSuperviseurs = () => {
        return (!props.model || !props.model.superviseurs) ?
            <></> :
            getOptions(props.model.superviseurs, i => i.id!, i => i.nomPrenom!, 'Sélectionner...')
    }

    const getRoles = () => {
        if (!props.model || !props.model.roles || !props.model.usagerSelectionne)
            return <></>;

        const checkboxes = props.model.roles.map(r => 
            <ValidationField type='checkbox' register={register} key={`${usagerValidations.roles}${r.role}}`}
                             field={usagerValidations.roles} errors={errors}
                             value={r.role?.toString()} label={r.nom} />);
        return <>{checkboxes}</>;
    };

    const getDepartements = () => {
        return (!props.model || !props.model.departements) ?
            <></> :
            getOptions(props.model.departements, i => i.id!, i => i.nom!, 'Sélectionner...')
    }

    const getSuccursales = () => {
        return (!props.model || !props.model.succursales) ?
            <></> :
            getOptions(props.model.succursales, i => i.id!, i => i.nom!, 'Sélectionner...')
    }

    const showCourrielPermis = () => {
        props.model.showCourrielPermis = !props.model.showCourrielPermis;
        props.setModel(clone(props.model));
    }

    // Html
    const popoverConfirmation = (
        <Popover>
            <Popover.Header as="h3">Confirmation</Popover.Header>
            <Popover.Body>
                <p>Vos modifications seront perdues, voulez-vous continuer ?</p>
                <Button className="mx-2" variant="secondary" onClick={() => { setShowConfirmation(false) }}>Non</Button>
                <Button variant="primary" onClick={() => closeCurrentWindow(props.model)}>Oui</Button>
            </Popover.Body>
        </Popover>
    );

    return (
        <Modal show={props.model.showActiveUsager} backdrop="static" keyboard={false} centered={true}
            onHide={() => closeCurrentWindow(props.model)}>
            <Modal.Header closeButton closeLabel={''}>
                <Modal.Title>{(usager.id === 0 ? 'Ajouter' : 'Modifier')} un usager</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="pb-1">
                    <Col className="col-4">
                        <Form.Label className="pt-2 obligatoire" htmlFor={usagerValidations.prenom.register[0]}>
                            {usagerValidations.prenom.label}
                        </Form.Label>
                    </Col>
                    <Col className="col-8">
                        <ValidationField type='text' register={register} field={usagerValidations.prenom} errors={errors}
                            autoFocus={true} disabled={usager.isDisabled} />
                    </Col>
                </Row>
                <Row className="pb-1">
                    <Col className="col-4">
                        <Form.Label className="pt-2 obligatoire" htmlFor={usagerValidations.nom.register[0]}>
                            {usagerValidations.nom.label}
                        </Form.Label>
                    </Col>
                    <Col className="col-8">
                        <ValidationField type='text' register={register} field={usagerValidations.nom} errors={errors} disabled={usager.isDisabled} />
                    </Col>
                </Row>
                <Row className="pb-1">
                    <Col className="col-4">
                        <Form.Label className="pt-2 obligatoire" htmlFor={usagerValidations.courriel.register[0]}>
                            {usagerValidations.courriel.label}
                        </Form.Label>
                    </Col>
                    <Col className="col-8">
                        <ValidationField className="float-start" type='text' register={register} field={usagerValidations.courriel}
                            errors={errors} disabled={usager.isDisabled} showError={false} />
                        <ErrorMessage name={'courriel'} errors={errors} render={({ message }) =>
                            <Badge bg="danger float-start badge-error">{message}</Badge>} />
                    </Col>
                </Row>
                <Row className="pb-1 warning">
                    <Col className="col-12 alert alert-info" onClick={showCourrielPermis}
                         style={{fontSize: "0.8em", padding: "0.5em", cursor: "pointer", marginBottom:"0"}}>
                        <strong>Cliquer ici pour voir les domaines permis pour les courriels</strong>
                        <ul hidden={!props.model.showCourrielPermis} style={{marginBottom:"0"}}>
                            {props.model.domaineCourrielPermis?.map(d => <li key={d}>{d}</li>)}
                        </ul>
                    </Col>
                </Row>
                <Row className="pb-1">
                    <Col className="col-4">
                        <Form.Label className="pt-2" htmlFor={usagerValidations.superviseurId.register[0]}>
                            {usagerValidations.superviseurId.label}
                        </Form.Label>
                    </Col>
                    <Col className="col-8">
                        <ValidationField type='select' register={register} field={usagerValidations.superviseurId} errors={errors}
                            options={getSuperviseurs()} disabled={usager.isDisabled} />
                    </Col>
                </Row>
                <Row className="pb-1">
                    <Col className="col-4">
                        <Form.Label className="pt-2" htmlFor={usagerValidations.roles.register[0]}>
                            {usagerValidations.roles.label}
                        </Form.Label>
                    </Col>
                    <Col className="col-8 mb-3">
                        {getRoles()}
                    </Col>
                </Row>
                <Row className="pb-1">
                    <Col className="col-4">
                        <Form.Label className="pt-2 obligatoire" htmlFor={usagerValidations.departementId.register[0]}>
                            {usagerValidations.departementId.label}
                        </Form.Label>
                    </Col>
                    <Col className="col-8">
                        <ValidationField type='select' register={register} field={usagerValidations.departementId} errors={errors}
                            options={getDepartements()} clearErrors={clearErrors} disabled={usager.isDisabled} />
                    </Col>
                </Row>
                <Row className="pb-1">
                    <Col className="col-4">
                        <Form.Label className="pt-2 obligatoire" htmlFor={usagerValidations.succursaleId.register[0]}>
                            {usagerValidations.succursaleId.label}
                        </Form.Label>
                    </Col>
                    <Col className="col-8">
                        <ValidationField type='select' register={register} field={usagerValidations.succursaleId} errors={errors}
                            options={getSuccursales()} clearErrors={clearErrors} disabled={usager.isDisabled} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="button" onClick={handleSubmit(save)} disabled={usager.isDisabled}>Ok</Button>
                <OverlayTrigger show={showConfirmation} placement="left" overlay={popoverConfirmation}>
                    <Button variant="secondary" type='button' onClick={askCloseConfirmation} disabled={usager.isDisabled}>Annuler</Button>
                </OverlayTrigger>
            </Modal.Footer>
        </Modal>
    )
}

