import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Container, Spinner } from "react-bootstrap";
import ValidationField, { formulaireBaseValidations } from "../../services/validations";
import { FormulaireBaseModel, UsagerModel } from "../../services/types";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { postReference, getReferenceType, getUsagerPageIndexPageSize, getUsagerAfterPageSize, getReferenceConsultationReferenceId } from "../../services/services";
import { defaultPageSize, delaiMessageConfirmation } from "../../utils/constantes";
import { useNavigate, useParams } from "react-router-dom";
import { ReferenceAssuranceEntreprisesUI } from '../formulaires/referenceAssuranceEntreprisesUI';
import { ReferenceAssurancesPariculiersUI } from '../formulaires/referenceAssurancePariculiersUI';
import { ReferenceAssuranceCollectiveUI } from '../formulaires/referenceAssuranceCollectiveUI';
import AlertNomReferenceurInvalideUI from '../formulaires/alertNomReferenceurInvalideUI';
import { AutoComplete } from 'react-autocomplete-input-component';
import { createSetError } from "../../utils/errorsUtils";
import { Id, toast, ToastContainer, ToastOptions } from "react-toastify";
import { ToastTimeout } from "../../utils/constantes";
import { clone } from "../../utils/cloneUtils";
import { usagerValidations } from "../../services/validations";
import { ReferenceServicesFinanciersUI } from "./referenceServicesFinanciersUI";
import './formulaire.css'
import { ReferenceCimeAssurancesParticuliersUI } from "./referenceCimeAssuranceParticuliersUI";

type ParamTypes = {
    type: string;
}
export function FormulaireUI(props: {referenceId?:number, referenceType?:string}) {

    // Membres
    const { type } = useParams<ParamTypes>();
    const isConsultation = props.referenceId === undefined ? false : true;

    // State
    const [model, setModel] = useState<FormulaireBaseModel>();
    const [isLoading, setIsLoading] = useState(false);

    // Use
    const { register, handleSubmit, formState: { errors }, reset, getValues, watch } = useForm<FormulaireBaseModel>();
    const setError = createSetError();
    const methods = useForm();
    const nav = useNavigate();

    let toastId: Id;

    const showToast = (message: string, options: ToastOptions, closeOnly?: boolean) => {
        if (toastId)
            toast.dismiss(toastId);
        if (!closeOnly)
            toastId = toast(message, options);
    }

    //Méthodes
    const getReferenceurIdentifiant = (usager:UsagerModel|undefined) => `${usager?.prenomNom} (${usager?.courriel})`

    useEffect(() => {

        addValidationsFormulaire();
        if (isLoading)
            return;
        
        setIsLoading(true);
        const inputAutoComplete = document.getElementById('nomReferenceur');
        inputAutoComplete?.setAttribute('disabled', "true");
        inputAutoComplete?.setAttribute('type', "search");

        (isConsultation ? 
            getReferenceConsultationReferenceId(props.referenceId!) : 
            getReferenceType(type as string))
                .then(data => {
                    reset(data);
                    setModel(data);
                    if (!isConsultation)
                        loadUsagers(inputAutoComplete!);
                    setTimeout(() => {
                        inputAutoComplete?.setAttribute('value', getReferenceurIdentifiant(data?.usagerSelectionne));    
                    }, 50);
                }).catch(setError)
                .finally(() => { if (isConsultation) setIsLoading(false) })
    }, []);

    const addValidationsFormulaire = () => { 
        formulaireBaseValidations.ville.register[1]['validate'] = val => {
            return (type === 'sf' && (val === '' || val === null))
                ? formulaireBaseValidations.ville.customValidatorMessage : true;
        }
        formulaireBaseValidations.servicesFinanciersDateNaissance.register[1]['validate'] = val => {
            return (type === 'sf' && (val === '' || val === null))
                ? formulaireBaseValidations.servicesFinanciersDateNaissance.customValidatorMessage : true;
        }
    }

    const loadUsagers = (inputAutoComplete:HTMLElement) => {

        let usagers:UsagerModel[] = [];
        getUsagerPageIndexPageSize(1, defaultPageSize)
            .then(data => {
                usagers = usagers.concat(data?.usagers ?? []);
            })
            .catch(setError)

        getUsagerAfterPageSize(defaultPageSize)
            .then(data => {
                usagers = usagers.concat(data);
            })
            .catch(setError)
            .finally(() => {
                inputAutoComplete?.removeAttribute('disabled')
                const mod = getValues();
                mod.usagers = usagers;
                setModel(mod);
                setIsLoading(false);
            })
    }

    const save: SubmitHandler<FormulaireBaseModel> = data => {
        
        model!.disableEnvoyer = true;
        setModel(clone(model));
        setIsLoading(true);
        
        const mod = prepareModelToSave(clone(data))
        return postReference(mod)
            .then(() => {
                showToast('Votre référence a été enregistrée avec succès.', { type: 'success', autoClose: 1000 });
                setTimeout(() => { 
                    setIsLoading(false);
                    nav('/') }, 2000);
            })
            .catch(err => {
                if (err.status === 404) {
                    model!.showCourrielInexistant = true;
                    model!.disableEnvoyer = false;
                    setModel(clone(model));
                }
                else {
                    setError(err)
                }
            })
            .finally(() => {
                window.setTimeout(() => {
                    model!.showCourrielInexistant = false;
                    setModel(clone(model));
                }, delaiMessageConfirmation);
            });
    }

    // Html
    const prepareModelToSave = (data:FormulaireBaseModel) : FormulaireBaseModel => {
        data.type = type;
        data.usagers = [];
        data.courrielReferenceur = model?.usagerSelectionne?.courriel ?? '';
        data.optionsMomentsContact = [];
        data.optionsTelephonesContact = [];
        data.usagerSelectionne = undefined;
        // Petite twist pour que le courriel vide puisse passer, si on focus dedans, la validation chie...
        data.courriel = data.courriel === '' ? undefined : data.courriel;

        if (data.type === 'sf')
        {
            data.servicesFinanciers!.optionsAssurance = [];
            data.servicesFinanciers!.optionsAutre = [];
            data.servicesFinanciers!.optionsEpargne = [];
            data.servicesFinanciers!.optionsPlanifaction = [];
            // Vraiment une twist étrange à faire...
            data.servicesFinanciers!.autres = typeof(data.servicesFinanciers?.autres) === 'string' ? [data.servicesFinanciers?.autres] : [];
        }
        return data;
    }

    const getOptionsCheckbox = (options:any[], prefix:string, name:string) => {
        const chks = options?.map(t =>
            <div key={`${prefix}${t.id}`}>
                <ValidationField type='checkbox' register={register} disabled={isConsultation}
                                 field={formulaireBaseValidations[name]} errors={errors}
                                 value={t.id} label={t.nom} />
            </div>
        );
        return <>{chks}</>;
    };

    const assuranceEntreprise = model?.type === 'ae' ? <ReferenceAssuranceEntreprisesUI errors={errors} register={register} 
        model={model?.assuranceEntreprise} nomTypeReference={model!.typeReference!.nomRole} isConsultation={isConsultation} /> : <></>;

    const assurancePariculiers = model?.type === 'ap' ? <ReferenceAssurancesPariculiersUI errors={errors} register={register} 
        model={model?.assuranceParticuliers} nomTypeReference={model!.typeReference!.nomRole} isConsultation={isConsultation} /> : <></>;

    const cimeAssurancePariculiers = model?.type === 'cime' ? <ReferenceCimeAssurancesParticuliersUI errors={errors} register={register} 
        model={model?.cimeAssuranceParticuliers} nomTypeReference={model!.typeReference!.nomRole} isConsultation={isConsultation} /> : <></>;

    const serviceFianciers = model?.type === 'sf' ? <ReferenceServicesFinanciersUI errors={errors} register={register} 
        model={model?.servicesFinanciers} nomTypeReference={model!.typeReference!.nomRole} isConsultation={isConsultation} /> : <></>;

    const serviceAssuranceCollective = model?.type === 'ac' ? <ReferenceAssuranceCollectiveUI errors={errors} register={register} 
        model={model?.assuranceCollective} nomTypeReference={model!.typeReference!.nomRole} isConsultation={isConsultation} /> : <></>;

    const autocompleteNom = <AutoComplete
        getPropValue={item => getReferenceurIdentifiant(item)}
        showAll={false}
        highlightFirstItem={true}
        clearOnSelect={false}
        inputProps={{
            placeholder: '',
            className: "form-control",
            id: formulaireBaseValidations.nomReferenceur.register[0],
            name: formulaireBaseValidations.nomReferenceur.register[0],
            onBlur: (evt) => {
                const nomReferenceur = evt.target.value;
                const usager = model?.usagers?.find(u => getReferenceurIdentifiant(u) === nomReferenceur);
                model!.usagerSelectionne = usager;
                setModel(clone(model));
            }
        }}
        list={model?.usagers}
        highlightedItem={{
            backgroundColor: "var(--bs-secondary)",
            color: "var(--bs-light)"
        }}
        listItemStyle={{
            paddingTop: "0.2em",
            paddingBottom: "0.2em",
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
            cursor: "pointer",
        }}
        dropDownStyle={{
            position: "absolute",
            margin:"3px",
            backgroundColor: "var(--bs-light)",
            borderColor: "var(--bs-dark)",
            width: "24.2em",
            'zIndex': "100"
        }}

        onSelect={(selectedItem, unusedList) => {
            if (typeof(selectedItem) === 'string') {
                model!.usagerSelectionne = model?.usagers?.find(u => getReferenceurIdentifiant(u) === selectedItem); 
            }
            else {
                model!.usagerSelectionne = model?.usagers?.find(u => getReferenceurIdentifiant(u) === getReferenceurIdentifiant(selectedItem));
            }
            setModel(clone(model));
        }}/>;

    const informationReferenceurHtml = isConsultation ?
        <Row className="alert alert-info">
            <Col className="col-4">Référant : {model?.nomReferenceur}</Col>
            <Col className="col-4 text-center">Succursale : {model?.usagerSelectionne?.nomSuccursale}</Col>
            <Col className="col-4 text-end">Département : {model?.usagerSelectionne?.nomDepartement}</Col>
        </Row> :
        <Row as='fieldset' className="pt-3">
            <legend>Vos informations personnelles</legend>
            <Row className="pb-1">
                <Col className='pb-1 col-md-2 col-12 pt-2'>
                    <Form.Label className="obligatoire" htmlFor={formulaireBaseValidations.nomReferenceur.register[0]}>
                        {formulaireBaseValidations.nomReferenceur.label}
                    </Form.Label>
                </Col>
                <Col className='pb-1 col-md-4 col-12'>
                    {autocompleteNom}
                    <div className="mt-1 ms-1 sm-explication">
                        Choisissez un autre employé en inscrivant son nom au lieu du votre.
                    </div>
                </Col>
                <Col className='pb-1 col-md-2 col-12 pt-2'>
                    <Form.Label htmlFor="nomSuccursale">
                        Votre succursale
                    </Form.Label>
                </Col>
                <Col className='pb-1 col-md-4 col-12'>
                    <Form.Control id="nomSuccursale" defaultValue={model?.usagerSelectionne?.nomSuccursale} disabled></Form.Control>
                </Col>
            </Row>
            <Row>
                <Col className='pb-1 col-md-2 col-12 pt-2'>
                    <Form.Label htmlFor={formulaireBaseValidations.courrielReferenceur.register[0]}>
                        {formulaireBaseValidations.courrielReferenceur.label}
                    </Form.Label>
                </Col>
                <Col className='pb-1 col-md-4 col-12'>
                    <div className="d-flex">
                        <Form.Control id={formulaireBaseValidations.courrielReferenceur.register[0]} defaultValue={model?.usagerSelectionne?.courriel} disabled></Form.Control>
                    </div>
                </Col>
                <Col className='pb-1 col-md-2 col-12 pt-2'>
                    <Form.Label htmlFor={usagerValidations.nomDepartement.register[0]}>
                        Votre département
                    </Form.Label>
                </Col>
                <Col className='pb-1 col-md-4 col-12'>
                    <Form.Control id={usagerValidations.nomDepartement.register[0]} defaultValue={model?.usagerSelectionne?.nomDepartement} disabled></Form.Control>
                </Col>
            </Row>
        </Row>

    return (<FormProvider {...methods}>
            <Container className={`spinner-container consultation-modal d-flex align-items-start justify-content-center${isConsultation && isLoading ? '' : ' d-none'}`}>
                <Spinner animation="border" variant="info" />
            </Container>
            <Container as={Form} hidden={isLoading && isConsultation}>
                <h1 hidden={isConsultation}>Référence - {model?.typeReference?.nomRole}</h1>
                <ToastContainer position="top-center" autoClose={ToastTimeout} hideProgressBar={false} newestOnTop={true}
                                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
                {informationReferenceurHtml}
                <Row as='fieldset' className="pt-3">
                    <legend>Informations du référé</legend>
                    <Row className="pt-1">
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label className="obligatoire" htmlFor={formulaireBaseValidations.nom.register[0]}>
                                {formulaireBaseValidations.nom.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.nom} autoFocus errors={errors} 
                                disabled={isConsultation} />
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.appartementBureau.register[0]}>
                                {formulaireBaseValidations.appartementBureau.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.appartementBureau} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.adresse.register[0]}>
                                {formulaireBaseValidations.adresse.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.adresse} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                    </Row>
                    <Row className="pt-1">
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.ville.register[0]} className={type === 'sf' ? 'obligatoire' : ''}>
                                {formulaireBaseValidations.ville.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.ville} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.codePostal.register[0]}>
                                {formulaireBaseValidations.codePostal.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.codePostal} errors={errors}
                                disabled={isConsultation}/>
                        </Col>
                    </Row>
                    <Row className="pt-1">
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.province.register[0]}>
                                {formulaireBaseValidations.province.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.province} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.courriel.register[0]}>
                                {formulaireBaseValidations.courriel.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.courriel} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.telephoneCellulaire.register[0]}>
                                {formulaireBaseValidations.telephoneCellulaire.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.telephoneCellulaire} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.telephoneResidence.register[0]}>
                                {formulaireBaseValidations.telephoneResidence.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.telephoneResidence} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pb-1 col-md-2 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.telephoneTravail.register[0]}>
                                {formulaireBaseValidations.telephoneTravail.label}
                            </Form.Label>
                        </Col>
                        <Col className='pb-1 col-md-4 col-12'>
                            <ValidationField type='text' register={register} field={formulaireBaseValidations.telephoneTravail} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className='col-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.momentsContactFavorables.register[0]}>
                                {formulaireBaseValidations.momentsContactFavorables.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-4'>
                            {getOptionsCheckbox(model?.optionsMomentsContact ?? [], 'momentContact', 'momentsContactFavorables')}
                        </Col>
                        <Col className='col-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.typesTelephoneFavorables.register[0]}>
                                {formulaireBaseValidations.typesTelephoneFavorables.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-4'>
                            {getOptionsCheckbox(model?.optionsTelephonesContact ?? [], 'telephoneFavorable', 'typesTelephoneFavorables')}
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col>
                            <Form.Label htmlFor={formulaireBaseValidations.autresInfos.register[0]}>
                                {formulaireBaseValidations.autresInfos.label}
                            </Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pb-1 col-md-12 col-12'>
                            <ValidationField type='textarea' rows={2} register={register} field={formulaireBaseValidations.autresInfos} errors={errors}
                                disabled={isConsultation} />
                        </Col>
                    </Row>
                </Row> 
                {assuranceEntreprise}
                {assurancePariculiers}
                {cimeAssurancePariculiers}
                {serviceFianciers}
                {serviceAssuranceCollective}
                <Row className="pt-3" hidden={isConsultation}>
                    <Col className="col-8 col-md-10 col-lg-9 mt-1">
                    </Col>
                    <Col className="col-4 col-md-2 col-lg-3 mt-1 text-center">
                        <Button type="submit" variant="primary" onClick={handleSubmit(save)} disabled={model?.disableEnvoyer}>
                            Envoyer la référence
                        </Button>
                        <AlertNomReferenceurInvalideUI model={model} setModel={setModel}/>
                    </Col>
                </Row>
            </Container>
        </FormProvider>);
}