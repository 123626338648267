import { useMsal } from "@azure/msal-react";
import { faQuestion, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { goToLogin } from "../../services/Authentification/AuthConfig";
import { getCurrentAccount } from "../../services/Authentification/AuthFunction";
import './header.css'
import { getCurrentUsager, getResponsables, resetCurrentUsager, resetResponsable } from "../../utils/communUtils";
import { ResponsableModel, UsagerRoleModel } from "../../services/types";
import { useEffect, useState } from "react";

export const HeaderUI = (props: { isAuthenticated:boolean}) => {

    const { instance } = useMsal();

    // State
    const [responsablesHtml, setResponsablesHtml] = useState(<></>);
    const [currentUsager, setCurrentUsager] = useState<UsagerRoleModel>();
    const [nomUsager, setNomUsager] = useState('');

    // Méthodes 
    async function redirectTotal() {
        resetResponsable();
        resetCurrentUsager();
        await instance.logoutRedirect({
            account: getCurrentAccount(),
            postLogoutRedirectUri: "/"
        });
    }

    useEffect(() => { 
        if (location.href.indexOf("gettoken") === -1 && props.isAuthenticated) {
            getResponsables().then(data => {
                const respns = new Array<ResponsableModel>();
                for (const key in data) {
                    respns.push(data[key])
                }
                const navItems = respns.map(
                    x => <NavDropdown.Item href={`/formulaire/${x.codeTypeReference}`} className="header-dropdown-item" key={x.codeTypeReference}>
                        {x.nomCourtTypeReference}
                    </NavDropdown.Item>)
                setResponsablesHtml(<><NavDropdown.Divider />{navItems}</>);
            });
            getCurrentUsager()?.then(data => {
                setNomUsager(`${data.prenom} ${data.nom}`)
                setCurrentUsager(data)
            });
        }
    }, [])

    // Html
    const userIdentificationMessage = props.isAuthenticated ? `Vous êtes actuellement connecté en tant que ${nomUsager}` : '';
    const userIdentification = props.isAuthenticated ? <span className='toolbar-label' title={userIdentificationMessage}>
        {nomUsager}<FontAwesomeIcon icon={faUserCircle} className="toolbar-icon" />
    </span> : <></>;

    
    const profileButton = !props.isAuthenticated ? <>
        <Nav.Link href='#' onClick={goToLogin}>Se connecter</Nav.Link>
    </> :
        <NavDropdown title={userIdentification} id="basic-nav-dropdown">
            <NavDropdown.Item href="#" className="header-dropdown-item" onClick={redirectTotal}>Se déconnecter</NavDropdown.Item>
        </NavDropdown>;

    const showSuivi = props.isAuthenticated && currentUsager?.isCourtierOrAdiministrateur;
    return <>
        <Navbar expand="md" className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
            <Container>
                <h1 className="pmtroy-logo"><a href='/'>pmt&nbsp;roy</a></h1>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="main-navbar">
                    <Container>
                        <div className="float-start">
                            <Nav className="mr-auto main-navbar">
                                <NavDropdown title="Employe" id="basic-nav-dropdown" hidden={!props.isAuthenticated}>
                                    <NavDropdown.Item href="/" className="header-dropdown-item">Accueil</NavDropdown.Item>
                                    {responsablesHtml}
                                </NavDropdown>
                                <Nav.Item hidden={!showSuivi}>
                                    <Nav.Link href="/suivi">Suivi des références</Nav.Link>
                                </Nav.Item>
                                <NavDropdown title="Administration" id="basic-nav-dropdown" hidden={!(props.isAuthenticated && currentUsager?.isAdministrateur) ?? true}>
                                    <NavDropdown.Item href="/administration/destinataires" className="header-dropdown-item">Gestion des destinataires</NavDropdown.Item>
                                    <NavDropdown.Item href="/administration/usagers" className="header-dropdown-item">Gestion des usagers</NavDropdown.Item>
                                </NavDropdown>
                                <div className="d-block d-lg-none">
                                    {profileButton}
                            </div>
                            </Nav>
                        </div>
                        <div className="navbar-nav ms-md-auto float-end d-none d-lg-block">
                            <div className='d-none d-md-flex toolbar-container'>
                                <div className='d-none d-md-flex toolbar-container'>
                                    {profileButton}
                                    <NavDropdown title={<FontAwesomeIcon icon={faQuestion} className="toolbar-icon" />} id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/aide/about" className="header-dropdown-item">À propos de...</NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Container fluid className="bg-light pmt-nom-app rounded-bottom">Références soumises par les employés</Container>
    </>;
}