import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getSuiviAssigneReferenceToConnectedUserReferenceId } from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";
import { createSetError } from "../../utils/errorsUtils";
import { ShortToastTimeout } from "../../utils/constantes";

type ParamTypes = {
    id: string;
}

export default function AutoAssignationReferenceUI() {

    // Membres
    const { id } = useParams<ParamTypes>();
    let isLoading = false;

    //State
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    // Use
    const nav = useNavigate();
    const setError = createSetError();

    //Méthodes
    const handleClose = () => {
        setShowModal(false);
        setTimeout(() => { nav('/suivi') }, ShortToastTimeout);
    }

    useEffect(() => {
        if (id == undefined || isLoading)
            return;

        isLoading = true;
        getSuiviAssigneReferenceToConnectedUserReferenceId(parseInt(id))
            .then(unusedData => {
                setShowModal(true);
                setTitle('Succès');
                setMessage("La référence vous a été assignée avec succès.");
            }).catch(err => {
                if (err.status === 406) {
                    setShowModal(true);
                    setTitle('Échec');
                    setMessage("La référence n'est plus disponible.");
                }
                else {
                    setError(err);
                }
            }).finally(() => { isLoading = false; })
    }, []);

    //Html
    const assignationValide = (show: boolean, title: string, message: string) => {
        return <>
            <Modal show={show} onHide={handleClose} backdrop="static"
                keyboard={false} >
                <Modal.Header closeLabel={''} closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>;

    }

    const display = assignationValide(showModal, title, message)

    return (
        <>
            {display}
        </>
    )
}