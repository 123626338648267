/**
 * You can modify this file
 *
 * @version 6
 *
 */
import { PublicClientApplication } from '@azure/msal-browser';
import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { goToLogin, loginRequest, msalConfig } from './Authentification/AuthConfig';
import { getCurrentAccount } from './Authentification/AuthFunction';

const baseConfig: AxiosRequestConfig = {
  baseURL: sessionStorage.getItem('environmentUrl') as string, // <--- Add your base url
  headers: {
    'Content-Encoding': 'UTF-8',
    Accept: 'application/json',
    'Content-Type': 'application/json-patch+json'
  }
  //paramsSerializer: (param) => qs.stringify(param, { indices: false }),
};

let axiosInstance: AxiosInstance;

function getAxiosInstance(unusedsecurity: Security): AxiosInstance {
  if (!axiosInstance) {
    axiosInstance = Axios.create(baseConfig);

    // Response interceptor
    axiosInstance.interceptors.response.use(
      (async (response: AxiosResponse): Promise<SwaggerResponse<any>> => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        /**
         * Example on response manipulation
         *
         * @example
         *   const swaggerResponse: SwaggerResponse = {
         *     ...response,
         *   };
         *   return swaggerResponse;
         */
        return response.data;
      }) as any,
      (error: AxiosError) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response) {
          if (error.response.status === 403) {
            if (location.href.indexOf('/forbid') === -1) location.replace('/forbid');
          }
          return Promise.reject(
            new RequestError(error.response.data as any, error.response.status, error.response)
          );
        }

        if (error.isAxiosError) {
          return Promise.reject(new RequestError('noInternetConnection'));
        }
        return Promise.reject(error);
      }
    );
  }

  // ًًRequest interceptor
  axiosInstance.interceptors.request.use(
    async (requestConfig) => {
      // Do something before request is sent
      /** Example on how to add authorization based on security */
      if (requestConfig.headers) {
        const token = await fetchAccessToken();
        requestConfig.headers.authorization = `Bearer ${token}`;
      }

      return requestConfig;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

class RequestError extends Error {
  constructor(public message: string, public status?: number, public response?: AxiosResponse) {
    super(message);
  }

  isApiException = true;

  static isRequestError(error: any): error is RequestError {
    return error.isApiException;
  }
}

export type Security = any[] | undefined;

// export interface SwaggerResponse<R> extends AxiosResponse<R> {}
export type SwaggerResponse<R> = R;

export { getAxiosInstance, RequestError };

export function fetchAccessToken() {
  const account = getCurrentAccount();

  if (account === undefined) return;

  return new Promise((resolve) => {
    const account = getCurrentAccount();
    const msal = new PublicClientApplication(msalConfig);
    msal
      .acquireTokenSilent({ ...loginRequest, account: account })
      .then((response) => {
        resolve(response.accessToken);
      })
      .catch((err) => {
        if (err.toString().indexOf('InteractionRequiredAuthError') >= 0) goToLogin();
      });
  });
}
