

export function getOptions<T>(list:Array<T>, 
                              getValue:(item:T) => string|number, 
                              getDescription:(item:T) => string|number,
                              firstItem?:string | undefined) : JSX.Element {

    const firstValue = list.length >= 1 && typeof(getValue(list[0]) == 'number') ? 0 : '';
    const opts = list.map(x => <option value={getValue(x)} key={getValue(x)} >{getDescription(x)}</option>);
    return firstItem ? <><option value={firstValue}>{firstItem}</option>{opts}</> : <>{opts}</>;
}