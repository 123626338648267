import { Card, Container } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import './accueil.css'
import { useEffect, useState } from "react";
import { CodeReferenceAssuranceCime, CodeReferenceAssuranceCollective, CodeReferenceAssuranceEntreprise, CodeReferenceAssuranceParticulier, CodeReferenceServicesFinanccier } from "../../utils/constantes";
import { ResponsableModel } from "../../services/types";
import { getResponsables } from "../../utils/communUtils";

export const AccueilUI = () => {
    

    // State
    const [responsables, setResponsables] = useState<{ [x: string]: ResponsableModel }>()
    
    // Use

    // Méthodes
    useEffect(() => {
        getResponsables()?.then(data => {
            setResponsables(data);
        });
    }, [])

    // Html
    if (!responsables)
        return <></>;

    return <>
        <Container className="mt-5 justify-content-center gap-3 d-flex flex-wrap">
            <Card className='pmt-card-accueil'>
                <CardHeader><h4>{responsables[CodeReferenceAssuranceEntreprise].nomTypeReference}</h4></CardHeader>
                <Card.Body>
                    <div>
                        Responsable :&nbsp;
                        <strong>{responsables[CodeReferenceAssuranceEntreprise].nomResponsable}</strong>
                    </div>
                    <div>
                        Courriel :&nbsp;
                        <a href={`mailto:${responsables[CodeReferenceAssuranceEntreprise].courriel}`}>
                            {responsables[CodeReferenceAssuranceEntreprise].courriel}
                        </a>
                    </div>
                </Card.Body>
                <Card.Footer className="text-end">
                    <a href={`formulaire/${responsables[CodeReferenceAssuranceEntreprise].codeTypeReference}`}>Accéder au formulaire</a>
                </Card.Footer>
            </Card>
            <Card className='pmt-card-accueil'>
                <CardHeader><h4>{responsables[CodeReferenceAssuranceParticulier].nomTypeReference}</h4></CardHeader>
                <Card.Body>
                    <div>
                        Responsable :&nbsp;
                        <strong>{responsables[CodeReferenceAssuranceParticulier].nomResponsable}</strong>
                    </div>
                    <div>
                        Courriel :&nbsp;
                        <a href={`mailto:${responsables[CodeReferenceAssuranceParticulier].courriel}`}>
                            {responsables[CodeReferenceAssuranceParticulier].courriel}
                        </a>
                    </div>
                </Card.Body>
                <Card.Footer className="text-end">
                    <a href={`formulaire/${responsables[CodeReferenceAssuranceParticulier].codeTypeReference}`}>Accéder au formulaire</a>
                </Card.Footer>
            </Card>
            <Card className='pmt-card-accueil'>
                <CardHeader><h4>{responsables[CodeReferenceServicesFinanccier].nomTypeReference}</h4></CardHeader>
                <Card.Body>
                    <div>
                        Responsable :&nbsp;
                        <strong>{responsables[CodeReferenceServicesFinanccier].nomResponsable}</strong>
                    </div>
                    <div>
                        Courriel :&nbsp;
                        <a href={`mailto:${responsables[CodeReferenceServicesFinanccier].courriel}`}>
                            {responsables[CodeReferenceServicesFinanccier].courriel}
                        </a>
                    </div>
                </Card.Body>
                <Card.Footer className="text-end">
                    <a href={`formulaire/${responsables[CodeReferenceServicesFinanccier].codeTypeReference}`}>Accéder au formulaire</a>
                </Card.Footer>
            </Card>
            <Card className='pmt-card-accueil'>
                <CardHeader><h4>{responsables[CodeReferenceAssuranceCollective].nomTypeReference}</h4></CardHeader>
                <Card.Body>
                    <div>
                        Responsable :&nbsp;
                        <strong>{responsables[CodeReferenceAssuranceCollective].nomResponsable}</strong>
                    </div>
                    <div>
                        Courriel :&nbsp;
                        <a href={`mailto:${responsables[CodeReferenceAssuranceCollective].courriel}`}>
                            {responsables[CodeReferenceAssuranceCollective].courriel}
                        </a>
                    </div>
                </Card.Body>
                <Card.Footer className="text-end">
                    <a href={`formulaire/${responsables[CodeReferenceAssuranceCollective].codeTypeReference}`}>Accéder au formulaire</a>
                </Card.Footer>
            </Card>
            <Card className='pmt-card-accueil'>
                <CardHeader><h4>{responsables[CodeReferenceAssuranceCime].nomTypeReference}</h4></CardHeader>
                <Card.Body>
                    <div>
                        Responsable :&nbsp;
                        <strong>{responsables[CodeReferenceAssuranceCime].nomResponsable}</strong>
                    </div>
                    <div>
                        Courriel :&nbsp;
                        <a href={`mailto:${responsables[CodeReferenceAssuranceCime].courriel}`}>
                            {responsables[CodeReferenceAssuranceCime].courriel}
                        </a>
                    </div>
                </Card.Body>
                <Card.Footer className="text-end">
                    <a href={`formulaire/${responsables[CodeReferenceAssuranceCime].codeTypeReference}`}>Accéder au formulaire</a>
                </Card.Footer>
            </Card>
        </Container>
    </>;
}