import { faArrowRightFromBracket, faPen, faRectangleList, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import DataTable, { TableColumn } from "react-data-table-component";
import { SubmitHandler, useForm } from "react-hook-form";
import { getSuivi, getSuiviSingleReferenceId, postSuivi } from "../../services/services";
import { ReferenceModel, SuiviModel } from "../../services/types";
import ValidationField, { suiviValidations } from "../../services/validations";
import { createSetError } from "../../utils/errorsUtils";
import './suivi.css'
import { clone } from "../../utils/cloneUtils";
import ChangementAssignationUI from "./changementAssignationUI";
import { Id, ToastContainer, ToastOptions, toast } from "react-toastify";
import { ToastTimeout } from "../../utils/constantes";
import ChangementStatutUI from "./changementStatutUI";
import ChangementChangementAutresInformationsUI from "./changementChangementAutresInformationsUI";
import ConsulterFormulaireUI from "./consulterFormulaireUI";
import { useParams } from "react-router-dom";

export const applyReferencesFilter = (model:SuiviModel):SuiviModel => {
   
    const text = model.texteFiltre?.replace("(", "\\(").replace(")", "\\)");
    const words = text?.split(' ') ?? [];
    const regexText = words.length > 0 ? new RegExp(`${words.join('|')}`, 'i') : undefined;
    model.referencesFiltered = model.references!.filter(r => regexText?.test(r.fieldRecherche!))
    return model;
}

type ParamTypes = {
    id: string | undefined;
}

export const SuiviUI = () => {

    // Membres
    const colonnes: TableColumn<ReferenceModel>[] = [
        {
            name: "Nom de la référence",
            cell: (row:ReferenceModel) => `${row.nom} (${row.codeFormulaire})`,
            sortable: true,
            wrap: true
        },
        {
            name: "Date inscription",
            selector: (row:ReferenceModel) => `${row.dateCreation}`,
            sortable: true,
            wrap: true
        },
        {
            name: "Référant",
            cell: (row:ReferenceModel) => getNomReferenceur(row),
            sortable: true,
            wrap: false
        },
        {
            name: "Assignation",
            sortable: true,
            selector: (row:ReferenceModel) => `${row.nomCourtier}`,
            wrap: true,
        },
        {
            name: "Statut",
            sortable: true,
            selector: (row:ReferenceModel) => `${row.nomStatut}`,
            wrap: true,
            center: true
        },
        {
            name: 'Actions',
            cell: (row:ReferenceModel) => {
                return <>
                    <Button size="sm" className="me-1" title='Consulter' onClick={() => openConsultation(row)}>
                        <FontAwesomeIcon icon={faRectangleList}/>
                    </Button>
                    <Button size="sm" className="me-1" title='Modifier les autres informations' disabled={!row.canEditInformation}
                            onClick={() => openAutresInformations(row)} >
                        <FontAwesomeIcon icon={faPen}/>
                    </Button>
                    <Button size="sm" className="me-1" title='Modifier le statut' disabled={!row.canChangeStatut}
                            onClick={() => openChangementStatut(row)} >
                        <FontAwesomeIcon icon={faArrowRightFromBracket}/>
                    </Button>
                    <Button size="sm" title="Réassigner/assigner" disabled={!row.canChangeAssignation}
                            onClick={() => openChangementAssignation(row)} >
                        <FontAwesomeIcon icon={faUserCheck} />
                    </Button>
                </>;
            },
            wrap: true,
            right: true
        }
    ];
    let toastId:Id;
    const { id } = useParams<ParamTypes>();

    // Use
    const setError = createSetError();
    const { register, reset, handleSubmit, formState: { errors } } = useForm<SuiviModel>();

    // State
    const [model, setModel] = useState<SuiviModel>();

    // Méthode
    useEffect(() => {
         (id === undefined ? getSuivi([]) : getSuiviSingleReferenceId(parseInt(id), []))
        .then(data => {
            data.referencesFiltered = data.references;
            reset(data);
            setModel(data);
        })
        .catch(setError);
    }, []);

    const openChangementAssignation = (reference:ReferenceModel) => {
        model!.showChangementAssignation = true; 
        model!.referenceSelectionnee = reference;
        setModel(clone(model))
    }

    const openChangementStatut = (reference:ReferenceModel) => {
        model!.showChangementStatut= true; 
        model!.referenceSelectionnee = reference;
        model!.statutIdSelectionne = reference.statutId?.toString() ?? '';
        setModel(clone(model))
    }

    const openAutresInformations = (reference:ReferenceModel) => {
        model!.showAutresInformation = true; 
        model!.referenceSelectionnee = reference;
        setModel(clone(model))
    }

    const openConsultation = (reference:ReferenceModel) => {
        model!.showConsultation = true; 
        model!.referenceSelectionnee = reference;
        setModel(clone(model))
    }

    const save: SubmitHandler<SuiviModel> = async data => {
        postSuivi(data.statusSelected)
            .then(data => {
                model!.references = data.references;
                const m = applyReferencesFilter(model!);
                setModel(clone(m));
            })
            .catch(setError);
    };

    const applyFilter = (text:string) => {
        model!.texteFiltre = text;
        const m = applyReferencesFilter(model!);
        setModel(clone(m));
    }
    
    const showToast = (message:string, options:ToastOptions, closeOnly?:boolean) => {
        if (toastId)
            toast.dismiss(toastId);
        if (!closeOnly)
            toastId = toast(message, options);
    }

    // Html
    const getStatuts = () => {
        if (!model || !model.statuts)
            return <></>;
        
        let i = 0;
        const checkboxes = model.statuts.map(s => {
            return <div className="col-6" key={`status${s.id}`}>
                <ValidationField type='checkbox' register={register} key={`${suiviValidations.statuts}${s.id}`}
                                 field={suiviValidations.statusSelected} errors={errors} showError={i++ == 0}
                                 value={s.id?.toString()} label={`${s.nom} (${s.countReferences})`} />
            </div>
        });
        return <>{checkboxes}</>
    }

    const getNomReferenceur = (row:ReferenceModel) => {
        if (row.nomReferenceur === row.nomUsager)
            return <>{row.nomReferenceur}</>
        return <div>{row.nomReferenceur}
                <div className="mt-1 nom-usager">{row.nomUsager}</div>
            </div>
    }

    const noDataHtml = id === undefined ?
        <div className="text-center fw-bold alert alert-dismissible alert-info mt-3">
            Aucune référence à suivre.
        </div> :
        <div className="text-center fw-bold alert alert-dismissible alert-danger mt-3">
            La référence demandée n'est pas disponible pour vous.
        </div>

    if (!model || !model.references)
        return <></>

    return <>
        <ToastContainer position="top-center" autoClose={ToastTimeout} hideProgressBar={false} newestOnTop={true}
                        closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        <h1>{id === undefined ? 'Suivi des références' : 'Agir sur la référence'}</h1>
        <Row hidden={id !== undefined}>
            <Col className="col-6">
                <Card>
                    <CardHeader>
                        <h4>Choisir les données</h4>
                    </CardHeader>
                    <Card.Body>
                        <Form.Label htmlFor={suiviValidations.statusSelected.register[0]}>
                            {suiviValidations.statusSelected.label}
                        </Form.Label>
                        <Row>
                            {getStatuts()}
                        </Row>
                        <Row>
                            <Col>
                                <Button size="sm" className="float-end ms-1" title="Réassigner/assigner" type='submit' 
                                    onClick={handleSubmit(save)}>Rafraîchir</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="col-6">
                <Card>
                    <CardHeader>
                        <h4>Filtrer les références</h4>
                    </CardHeader>
                    <Card.Body>
                        <Form.Label htmlFor={suiviValidations.texteFiltre[0]}>Inscrire un nom ou un statut</Form.Label>
                        <Form.Control type="search" name="texteFiltre" autoFocus onChange={evt => {applyFilter(evt.target.value)}} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <DataTable data={model.referencesFiltered ?? []}
                   columns={colonnes}
                   theme={"default"}
                   noDataComponent={noDataHtml}
                   persistTableHead={false}
                   striped={true}
                   highlightOnHover={true}
                   responsive={true}
                   dense={false} />
        <Container className={`mt-5 justify-content-center gap-3 d-flex flex-wrap d-${id === undefined ? 'none' : 'block'}`}>
            <Button onClick={() => location.href = '/suivi'}>Revenir au suivi</Button>
        </Container>
        <ChangementStatutUI model={model} setModel={setModel} showToast={showToast}/> 
        <ChangementAssignationUI model={model} setModel={setModel} showToast={showToast}/>
        <ChangementChangementAutresInformationsUI model={model} setModel={setModel} showToast={showToast}/>
        <ConsulterFormulaireUI model={model} setModel={setModel}/>
    </>
}