import { Container } from "react-bootstrap";

export function UnauthorizedUI() {

    return <Container>
        <h1 className='text-danger'>Oups...</h1>
        <h2 className='text-danger'>Vous n'avez pas accès à la ressource demandée.</h2>
        <p>
            Afin d'accéder à cette application, vous devez vous authentifier. Cliquez sur le bouton <span className='fw-bold'>Se connecter</span> ci-haut.
        </p>
    </Container>
}