import { UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import ValidationField, { formulaireBaseValidations } from "../../services/validations";
import { Col, Form, Row } from 'react-bootstrap';
import { AssuranceEntrepriseModel } from "../../services/types";

export function ReferenceAssuranceEntreprisesUI(props: {
    model?: AssuranceEntrepriseModel | Partial<AssuranceEntrepriseModel>,
    register: UseFormRegister<any>,
    errors: Partial<FieldErrorsImpl>,
    nomTypeReference:string|undefined,
    isConsultation?:boolean
}) {

    if (props.model === undefined)
        return <></>;
        
    return (
        <>
            <Row as='fieldset' className="pt-3">
                <legend>{props.nomTypeReference}</legend>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceEntrepriseDateRenouvellement.register[0]}>
                            {formulaireBaseValidations.assuranceEntrepriseDateRenouvellement.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='date' register={props.register} field={formulaireBaseValidations.assuranceEntrepriseDateRenouvellement} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceEntrepriseAssureurActuel.register[0]}>
                            {formulaireBaseValidations.assuranceEntrepriseAssureurActuel.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='text' register={props.register} field={formulaireBaseValidations.assuranceEntrepriseAssureurActuel} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceEntrepriseProvenanceReference.register[0]}>
                            {formulaireBaseValidations.assuranceEntrepriseProvenanceReference.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='texte' register={props.register} field={formulaireBaseValidations.assuranceEntrepriseProvenanceReference} 
                            errors={props.errors} disabled={props.isConsultation}/>
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceEntrepriseRaisonMagasinage.register[0]}>
                            {formulaireBaseValidations.assuranceEntrepriseRaisonMagasinage.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='text' register={props.register} field={formulaireBaseValidations.assuranceEntrepriseRaisonMagasinage} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceEntrepriseNomEntreprise.register[0]}>
                            {formulaireBaseValidations.assuranceEntrepriseNomEntreprise.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='texte' register={props.register} field={formulaireBaseValidations.assuranceEntrepriseNomEntreprise} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
            </Row>
        </>
    );
}
