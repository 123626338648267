import { faPen, faPersonCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import CardHeader from "react-bootstrap/esm/CardHeader"
import DataTable, { TableColumn } from "react-data-table-component"
import { createSetError } from "../../utils/errorsUtils";
import { deleteUsager, getUsagerAfterPageSize, getUsagerPageIndexPageSize } from "../../services/services";
import { UsagerModel, UsagersModel } from "../../services/types";
import { clone } from "../../utils/cloneUtils";
import DetailUsagerUI from "./detailUsagerUI";
import { Id, toast, ToastContainer, ToastOptions } from "react-toastify";
import { ToastTimeout, defaultPageSize } from "../../utils/constantes";
import { useNavigate } from "react-router-dom";
import { getCurrentUsager } from "../../utils/communUtils";

export const applyFilter = (text: string | undefined, usagers: UsagerModel[]) => {
    text = text?.replace("(", "\\(").replace(")", "\\)");
    const texts = text?.split(' ') ?? [];
    const regex = new RegExp(`${texts.join('|')}`, 'i');
    return usagers?.filter(u => regex.test(u.findingField!));
}

export const sortUsagers = (sortField: string, usagers: UsagerModel[]) => {
    return usagers.sort((a, b) => {
        let textA = '';
        let textB = '';
        switch (sortField) {
            case 'nomPrenom':
                textA = a.nomPrenom!.toLowerCase();
                textB = b.nomPrenom!.toLowerCase();
                break;
            case 'courriel':
                textA = a.courriel!.toLowerCase();
                textB = b.courriel!.toLowerCase();
                break;
            case 'nomSuccursale':
                textA = a.nomSuccursale!.toLowerCase();
                textB = b.nomSuccursale!.toLowerCase();
                break;
            case 'nomDepartement':
                textA = a.nomDepartement!.toLowerCase();
                textB = b.nomDepartement!.toLowerCase();
                break;
            case 'nomSuperviseur':
                textA = a.nomSuperviseur!.toLowerCase();
                textB = b.nomSuperviseur!.toLowerCase();
                break;
        }
        return textA < textB ? -1 : (textA > textB ? 1 : 0);
    })
}

export const GestionUsagerUI = () => {

    // Membres
    let indexRoles = 0;
    let isLoading = false;
    let toastId: Id;
    const colonnes: TableColumn<UsagerModel>[] = [
        {
            name: "Nom",
            sortable: true,
            selector: (row: UsagerModel) => `${row.nomPrenom}`
        },
        {
            name: "Courriel",
            sortable: true,
            selector: (row: UsagerModel) => `${row.courriel}`
        },
        {
            name: "Rôle",
            sortable: true,
            cell: (row: UsagerModel) => <div title={row.nomRole?.replace(',', '\n')}>
                {row.nomRole?.split(',').map(r => <div className="d-inline-block text-truncate" style={{ maxWidth: '100px' }} key={`NomRole${indexRoles++}`}>{r}</div>)}
            </div>
        },
        {
            name: "Superviseur",
            sortable: true,
            selector: (row: UsagerModel) => row.nomSuperviseur ?? '',
            wrap: true,
        },
        {
            name: "Département",
            sortable: true,
            selector: (row: UsagerModel) => row.nomDepartement ?? '',
            wrap: true,
        },
        {
            name: "Succursale",
            sortable: true,
            selector: (row: UsagerModel) => row.nomSuccursale ?? '',
            wrap: true,
        },
        {
            name: <>
                <span className="me-3">Actions</span>
                <Button className="ms-1" size="sm" title="Ajouter un usager" onClick={() => openDetailUsagerUI(0)}>
                    <FontAwesomeIcon icon={faPersonCirclePlus} />
                </Button>
            </>,
            width: '15%',
            cell: (row: UsagerModel) => {
                return <>
                    <Button size="sm" className="me-1" title="Modifier" onClick={() => openDetailUsagerUI(row.id ?? 0)}><FontAwesomeIcon icon={faPen} /></Button>
                    <Button size="sm" title="Supprimer" onClick={() => openDeletionConfirmation(row)}><FontAwesomeIcon icon={faTrash} /></Button>
                </>;
            },
            wrap: true,
            right: true
        }
    ];

    // Use
    const setError = createSetError();
    const nav = useNavigate();
    
    // State
    const [model, setModel] = useState<UsagersModel>();

    // Méthodes
    getCurrentUsager().then(data => {
        if (!data.isAdministrateur)
            nav('/forbid');
    });
    
    useEffect(() => {
        if (isLoading)
            return;

        isLoading = true;
        getUsagerPageIndexPageSize(1, defaultPageSize)
            .then(data => {
                data.usagersFiltered = data.usagers;
                setModel(data);
                getNextPageUsagers(data);
            })
            .catch(setError);
    }, []);

    const getNextPageUsagers = (model: UsagersModel) => {

        getUsagerAfterPageSize(defaultPageSize)
            .then(data => {
                model.usagers = model.usagers?.concat(data);
                model.usagersFiltered = model.usagers;
                search(model.texteFiltre, model);
            })
            .catch(setError)
            .finally(() => isLoading = false);
    }

    const search = (text: string | undefined, modelFromServer?: UsagersModel | undefined) => {

        const currentModel = modelFromServer ?? model;
        if (currentModel === undefined)
            return;

        currentModel.texteFiltre = text;
        const usagersFiltered = applyFilter(text, currentModel.usagers ?? []);
        if (usagersFiltered != undefined) {
            currentModel.usagersFiltered = usagersFiltered;
        }
        setModel(clone(currentModel));
    }

    const openDetailUsagerUI = (id: number) => {
        if (model === undefined || !model.usagers)
            return;

        const usager = id === 0 ? model?.emptyUsagerModel : model.usagers.find(u => u.id === id);
        model.showDeletionConfirmation = false;
        model.showActiveUsager = true;
        model.usagerSelectionne = usager;
        setModel(clone(model));
    }

    const showToast = (message: string, options: ToastOptions, closeOnly?: boolean) => {
        if (toastId)
            toast.dismiss(toastId);
        if (!closeOnly)
            toastId = toast(message, options);
    }

    const deleteSelectedUsager = () => {
        if (!model || !model.usagerSelectionne || !model.usagers)
            return;

        showToast('Suppression en cours', { type: 'info', autoClose: false });
        deleteUsager({ idUsager: model.usagerSelectionne.id ?? 0 })
            .then(() => {
                showToast('', {}, true);
                const index = model.usagers!.findIndex(u => u.id === model.usagerSelectionne!.id);
                model.usagers!.splice(index, 1);
                model.usagersFiltered = applyFilter(model.texteFiltre, model.usagers!);
                model.usagersFiltered = sortUsagers(model.sortField!, model.usagersFiltered);
                model.showDeletionConfirmation = false;
                showToast('Succès', { type: 'success', autoClose: 1000 });
                setModel(clone(model));
            })
            .catch(setError);
    }

    const openDeletionConfirmation = (usager: UsagerModel) => {
        if (!model || !model.usagerSelectionne)
            return;

        model.showDeletionConfirmation = true;
        model.usagerSelectionne = usager;
        setModel(clone(model));
    }

    const closeDeletionConfirmation = (usager: UsagerModel) => {
        if (!model || !model.usagersFiltered || !model.usagerSelectionne)
            return;

        model.showDeletionConfirmation = false;
        model.usagerSelectionne = usager;
        setModel(clone(model));
    }

    // Html
    if (model === undefined || model.usagers === undefined)
        return <></>;

    return <>
        <h1>Gestions des usagers</h1>
        <Form>
            <ToastContainer position="top-center" autoClose={ToastTimeout} hideProgressBar={false} newestOnTop={true}
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardHeader>
                            <h4>Filtrer les usagers</h4>
                        </CardHeader>
                        <Card.Body>
                            <Form.Label htmlFor="texteFiltre">Inscrire un nom, un prénom, un courriel, un superviseur, un département ou le nom d'une succursale à chercher</Form.Label>
                            <Form.Control type="search" name="texteFiltre" autoFocus onChange={evt => search(evt.target.value)} />
                        </Card.Body>
                    </Card>
                    <DataTable data={model.usagersFiltered ?? []}
                        columns={colonnes}
                        theme={"default"}
                        noDataComponent={
                            <div className="text-center fw-bold alert alert-dismissible alert-info mt-3">
                                Aucun usager ne correspond au texte inscrit.
                            </div>}
                        persistTableHead={false}
                        striped={true}
                        highlightOnHover={true}
                        responsive={true}
                        dense={false}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 50]}
                        paginationComponentOptions={{ rowsPerPageText: '' }} />
                </Col>
            </Row>
            <DetailUsagerUI model={model} setModel={setModel} search={search} showToast={showToast} />
            <Modal show={model.showDeletionConfirmation} centered={true}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Ëtes vous certains de vouloir supprimer {model?.usagerSelectionne?.prenomNom} ?</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" autoFocus onClick={() => closeDeletionConfirmation(model.usagerSelectionne!)}>Non</Button>
                    <Button variant="primary" onClick={deleteSelectedUsager}>Oui</Button>
                </Modal.Footer>
            </Modal>
        </Form>
    </>
}