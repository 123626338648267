import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FooterUI } from './components/footer/footerUI';
import { HeaderUI } from './components/header/headerUI';
import { AccueilUI } from './pages/accueil/accueilUI';
import AboutUI from './pages/aide/about/aboutUI';
import { FormulaireUI } from './pages/formulaires/formulaireUI';
import { GestionUsagerUI } from './pages/gestionUsagers/gestionUsagersUI';
import { GestionDestinatairesUI } from './pages/gestionDestinataires/gestionDestinatairesUI'
import { SuiviUI } from './pages/suivi/suiviUI';
import { GetTokenUI } from './pages/getToken/getTokenUI';
import { prepareConfig } from './services/Authentification/AuthConfig';
import AutoAssignationReferenceUI from './pages/actionsEmail/autoAssignationReference';
import './App.css';
import ForbiUI from './pages/errors/forbidUI';
import { UnauthorizedUI } from './components/unauthorized/unauthorizedUI';

function App() {

    const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
    useEffect(() => {
        prepareConfig(setMsalInstance);
    }, []);

    if (msalInstance == undefined)
        return <></>;

    return (
        <MsalProvider instance={msalInstance}>
            <Container className='pmtroy-content'>
                <UnauthenticatedTemplate>
                    <HeaderUI isAuthenticated={false} />
                    <UnauthorizedUI />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<AccueilUI />} />
                        </Routes>
                    </BrowserRouter>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <HeaderUI isAuthenticated={true} />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<AccueilUI />} />
                            <Route path="/aide/about" element={<AboutUI />} />
                            <Route path="/formulaire/:type" element={<FormulaireUI />} />
                            <Route path="/suivi/:id" element={<SuiviUI />} />
                            <Route path="/suivi" element={<SuiviUI />} />
                            <Route path="/administration/usagers" element={<GestionUsagerUI />} />
                            <Route path="/administration/destinataires" element={<GestionDestinatairesUI />} />
                            <Route path="/autoAssignation/:id" element={<AutoAssignationReferenceUI />} />
                            <Route path="/getToken" element={<GetTokenUI />} />
                            <Route path="/forbid" element={<ForbiUI />} />
                        </Routes>
                    </BrowserRouter>
                </AuthenticatedTemplate>
            </Container>
            <FooterUI />
        </MsalProvider>
    );
}

export default App;
