import { Button, Modal } from "react-bootstrap";
import { SuiviModel } from "../../services/types";
import { clone } from "../../utils/cloneUtils";
import { FormulaireUI } from "../formulaires/formulaireUI";

export default function ConsulterFormulaireUI(props: {
    model:SuiviModel,
    setModel: (setModel:SuiviModel) => void
}) {
    // Membres

    // Use

    // State

    // Méthodes
    const close = () => {
        props.model.showConsultation = false;
        props.setModel(clone(props.model));
    }

    // Html
    return <Modal show={props.model.showConsultation} keyboard={false} centered={true} 
        onHide={close} size='xl' scrollable={true}>
        <Modal.Header closeButton>
            <Modal.Title>{props.model.referenceSelectionnee?.nomTypeReference}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormulaireUI referenceId={props.model.referenceSelectionnee?.id} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="button" onClick={close}>Fermer</Button>
        </Modal.Footer>
    </Modal>
}