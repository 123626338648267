import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { clone } from "../../utils/cloneUtils";
import { ReferenceModel, SuiviModel } from "../../services/types";
import ValidationField, { referenceValidations } from "../../services/validations";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect } from "react";
import { ToastOptions } from "react-toastify";
import { ShortToastTimeout } from "../../utils/constantes";
import { putSuivi } from "../../services/services";
import { createSetError } from "../../utils/errorsUtils";
import { applyReferencesFilter } from "./suiviUI";

export default function ChangementChangementAutresInformationsUI(props: {
    model:SuiviModel,
    setModel: (model: SuiviModel) => void,
    showToast: (message: string, options: ToastOptions, closeOnly?: boolean) => void
}) {
    // Membres

    // Use
    const { register, reset, handleSubmit, formState: { errors } } = useForm<ReferenceModel>();
    const setError = createSetError();

    // Méthodes
    useEffect(() => {
        if (!props.model || !props.model.referenceSelectionnee)
            return;
        reset(props.model.referenceSelectionnee);
        
    }, [props.model]);

    const save: SubmitHandler<ReferenceModel> = async data => {
        
        putSuivi(data)
        .then(data => {
            props.showToast('Les informations ont été sauvegardées avec succès.', { type: 'success', autoClose: ShortToastTimeout });
            props.model.showAutresInformation = false;
            const index = props.model.references?.findIndex(r => r.id == data.id);
            props.model!.references![index!] = data;
            const m = applyReferencesFilter(props.model);
            props.setModel(clone(m));
        })
        .catch(setError);
    };

    // Html
    

    return <Modal show={props.model.showAutresInformation} backdrop="static" keyboard={false} centered={true}
                  onHide={() => {props.model.showAutresInformation = false; props.setModel(clone(props.model))}}>
        <Modal.Header closeButton>
            <Modal.Title>Modifier les autres informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="pb-1">
                <Col className="col-6">
                    <Form.Label className="pt-2 obligatoire" htmlFor={referenceValidations.codeClient.register[0]}>
                        {referenceValidations.codeClient.label}
                    </Form.Label>
                </Col>
                <Col className="col-6">
                    <ValidationField type='text' register={register} field={referenceValidations.codeClient} errors={errors}
                        autoFocus={true}  />
                </Col>
            </Row>
            <Row className="pb-1">
                <Col className="col-6">
                    <Form.Label className="pt-2 obligatoire" htmlFor={referenceValidations.numeroPolice.register[0]}>
                        {referenceValidations.numeroPolice.label}
                    </Form.Label>
                </Col>
                <Col className="col-6">
                    <ValidationField type='text' register={register} field={referenceValidations.numeroPolice} errors={errors} />
                </Col>
            </Row>            
            <Row className="pb-1">
                <Col className="col-6">
                    <Form.Label className="pt-2 obligatoire" htmlFor={referenceValidations.prime.register[0]}>
                        {referenceValidations.prime.label}
                    </Form.Label>
                </Col>
                <Col className="col-6">
                    <div className="input-group">
                        <ValidationField type='number' register={register} field={referenceValidations.prime} errors={errors} />
                        <span className="input-group-text">$</span>
                    </div>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="button" onClick={handleSubmit(save)}>Ok</Button>
            <Button variant="secondary" type='button' 
                    onClick={() => {props.model.showAutresInformation = false; props.setModel(clone(props.model))}}>Annuler</Button>
        </Modal.Footer>
    </Modal>
}