import { UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import ValidationField, { formulaireBaseValidations } from "../../services/validations";
import { Col, Form, Row } from 'react-bootstrap';
import { CimeAssuranceParticuliersModel } from "../../services/types";

export function ReferenceCimeAssurancesParticuliersUI(props: {
    model?: CimeAssuranceParticuliersModel | Partial<CimeAssuranceParticuliersModel>,
    register: UseFormRegister<any>,
    errors: Partial<FieldErrorsImpl>,
    nomTypeReference: string | undefined,
    isConsultation?: boolean
}) {

    //Méthodes
    function getClassesVehicules() {
        const options = props.model?.classesVehicule?.map(p => <option value={p.id} key={`pays_${p.id}`}>{p.nom}</option>)
        return <>
            <option value=''>Sélectionner...</option>
            {options}</>
    }

    function getEtatsVehicules() {
        const options = props.model?.etatsVehicule?.map(p => <option value={p.id} key={`pays_${p.id}`}>{p.nom}</option>)
        return <>
            <option value=''>Sélectionner...</option>
            {options}</>
    }
    //Html
    const selectClasseVehicule =  !props.isConsultation ?
        <><ValidationField type='select' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersClasseVehicule}
            errors={props.errors} options={getClassesVehicules()} hidden={props.isConsultation} /></> :
        <><ValidationField type='text' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersClasseVehicule}
            errors={props.errors} hidden={!props.isConsultation} disabled />
        </>;


    const selectEtatVehicule = !props.isConsultation ? 
        <><ValidationField type='select' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersEtatVehicule}
        errors={props.errors} options={getEtatsVehicules()} hidden={props.isConsultation} /> </> :
        <><ValidationField type='text' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersEtatVehicule}
            errors={props.errors} hidden={!props.isConsultation} disabled /></>;

    if (props.model === undefined)
        return <></>;

    return (
        <>
            <Row as='fieldset' className="pt-3">
                <legend>{props.nomTypeReference}</legend>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.cimeAssuranceParticuliersNomProprietaireVehicule.register[0]}>
                            {formulaireBaseValidations.cimeAssuranceParticuliersNomProprietaireVehicule.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='text' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersNomProprietaireVehicule}
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                    <Col className='col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.cimeAssuranceParticuliersDateNaissanceProprietaire.register[0]}>
                            {formulaireBaseValidations.cimeAssuranceParticuliersDateNaissanceProprietaire.label}
                        </Form.Label>
                    </Col>
                    <Col className='col-md-4 col-12'>
                        <ValidationField type='date' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersDateNaissanceProprietaire}
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.cimeAssuranceParticuliersAutresInformationsVehicule.register[0]}>
                            {formulaireBaseValidations.cimeAssuranceParticuliersAutresInformationsVehicule.label}
                        </Form.Label>
                    </Col>
                    <Col className='col-md-10 col-12'>
                        <ValidationField type='texte' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersAutresInformationsVehicule}
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>

                </Row>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.cimeAssuranceParticuliersClasseVehicule.register[0]}>
                            {formulaireBaseValidations.cimeAssuranceParticuliersClasseVehicule.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        {selectClasseVehicule}
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.cimeAssuranceParticuliersEtatVehicule.register[0]}>
                            {formulaireBaseValidations.cimeAssuranceParticuliersEtatVehicule.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        {selectEtatVehicule}
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.cimeAssuranceParticuliersPrixVehicule.register[0]}>
                            {formulaireBaseValidations.cimeAssuranceParticuliersPrixVehicule.label}
                        </Form.Label>
                    </Col>
                    <Col className='col-md-10 col-12'>
                        <ValidationField type='texte' register={props.register} field={formulaireBaseValidations.cimeAssuranceParticuliersPrixVehicule}
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
            </Row>
        </>
    );
}
