
import { useEffect, useState } from "react";
import { Button, Badge, Col, Form, Container, Row } from "react-bootstrap"
import { createSetError } from "../../utils/errorsUtils";
import { getDestinataires, putDestinataires } from "../../services/services";
import { DestinatairesModel } from "../../services/types";
import { Id, toast, ToastContainer, ToastOptions } from "react-toastify";
import { ShortToastTimeout } from "../../utils/constantes";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { clone } from "../../utils/cloneUtils";
import { destinataireValidations } from "../../services/validations";
import { getCurrentUsager, resetResponsable } from "../../utils/communUtils";
import { useNavigate } from "react-router-dom";


export const GestionDestinatairesUI = () => {

  // Use
  const setError = createSetError();
  const nav = useNavigate();
  let toastId: Id;
  const { register, reset, handleSubmit, getValues, formState: { errors, isValid } } = useForm<DestinatairesModel>({reValidateMode:'onChange'});

  // State
  const [model, setModel] = useState<DestinatairesModel>();

  //Méthodes
  getCurrentUsager().then(data => {
    if (!data.isAdministrateur)
        nav('/forbid');
  });

  const save: SubmitHandler<DestinatairesModel> = async unusedData => {
    if (!model)
      return;

    if (isValid) {
      model.destinataires = getValues().destinataires;
      putDestinataires(model)
      .then(data => {
        resetResponsable();
        setModel(clone(data));
        showToast('Vos modifications ont été enregistrés avec succès.', { type: 'success', autoClose: ShortToastTimeout });
      })
      .catch(setError);
    }
  };

  // Méthodes
  useEffect(() => {
    getDestinataires()
      .then(data => {
        setModel(data);
        reset(data);
      })
      .catch(setError);
  }, []);

  const showToast = (message: string, options: ToastOptions, closeOnly?: boolean) => {
    if (toastId)
      toast.dismiss(toastId);
    if (!closeOnly)
      toastId = toast(message, options);
  }
  
  // Html
  if (model === undefined || model?.destinataires === undefined)
    return <></>;

  const destinatairesHtml = model.destinataires.map((d, i) => {
    return <Row className="mb-1" key={`destinataire_${d.code}`}>
      <Col className="col-4">
        {d.nom}
      </Col>
      <Col className="col-4">
        <Form.Control maxLength={30} className={`${errors[`destinataires.nomResponsable.${i}`] ? 'is-invalid' : ''}`}
          {...register(`destinataires.${i}.nomResponsable`, destinataireValidations.nomResponsable.register[1])} />
        <ErrorMessage name={`destinataires.${i}.nomResponsable`}
          errors={errors} render={({ message }) => <Badge bg="danger float-start badge-error">{message}</Badge>} />
      </Col>
      <Col className="col-4">
        <Form.Control maxLength={100} className={`${errors[`destinataires.${i}.courriel`] ? 'is-invalid' : ''}`}
          {...register(`destinataires.${i}.courriel`, destinataireValidations.courriel.register[1])} />
        <ErrorMessage name={`destinataires.${i}.courriel`}
          errors={errors} render={({ message }) => <Badge bg="danger float-start badge-error">{message}</Badge>} />
      </Col>
    </Row>
  })

  return <>
    <h1>Gestions des destinataires</h1>
    <Container as={Form} className="pt-3">
      <ToastContainer position="top-center" autoClose={ShortToastTimeout} hideProgressBar={false} newestOnTop={true}
                      closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      {destinatairesHtml}
      <Row className="pt-3">
        <Col className="col-12 mt-1 text-end">
          <Button type="button" variant="primary" onClick={handleSubmit(save)}>
            Enregistrer
          </Button>
        </Col>
      </Row>
    </Container>
  </>
}