import { getCommunCurrentuser, getCommunResponsables } from "../services/services";
import { ResponsableModel, UsagerRoleModel } from "../services/types";

const responsablesKey = 'responsables'
const currentUserKey = 'currentUser'

export function getResponsables():Promise<{ [x: string]: ResponsableModel }> {

    const sessionResponsable = sessionStorage.getItem(responsablesKey);

    return new Promise<{ [x: string]: ResponsableModel }>(resolve => {
        if (sessionResponsable) {
            const responsables = JSON.parse(sessionResponsable);
            resolve(responsables);
        }
        else {
            getCommunResponsables()
                .then(data => {
                    sessionStorage.setItem(responsablesKey, JSON.stringify(data))
                    resolve(data);
                })
                .catch(err => console.error(err));
        }
    })
}

export function resetResponsable() {
    sessionStorage.removeItem(responsablesKey);
}

export function getCurrentUsager():Promise<UsagerRoleModel> {

    const sessionCurrentUsager = sessionStorage.getItem(currentUserKey);

    return new Promise<UsagerRoleModel>(resolve => {
        if (sessionCurrentUsager) {
            const usager = JSON.parse(sessionCurrentUsager);
            resolve(usager);
        }
        else {
            getCommunCurrentuser()
                .then(data => {
                    sessionStorage.setItem(currentUserKey, JSON.stringify(data))
                    resolve(data);
                })
                .catch(err => console.error(err));
        }
    })
}

export function resetCurrentUsager() {
    sessionStorage.removeItem(currentUserKey);
}