import { UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import ValidationField, { formulaireBaseValidations } from "../../services/validations";
import { Col, Form, Row } from 'react-bootstrap';
import { ServicesFinanciersModel } from "../../services/types";

export function ReferenceServicesFinanciersUI(props: {
    model?: ServicesFinanciersModel | Partial<ServicesFinanciersModel>,
    register: UseFormRegister<any>,
    errors: Partial<FieldErrorsImpl>,
    nomTypeReference:string|undefined,
    isConsultation?:boolean
}) {

    //Méthodes
    const getOptionsCheckbox = (options:any[], prefix:string, name:string) => {
        const chks = options?.map(t =>
            <div key={`${prefix}${t.id}`}>
                <ValidationField type='checkbox' register={props.register} disabled={props.isConsultation}
                                 field={formulaireBaseValidations[name]} errors={props.errors}
                                 value={t.id} label={t.nom} />
            </div>
        );
        return <>{chks}</>;
    };

    if (props.model === undefined)
        return <></>;

    return (
        <>
            <Row as='fieldset' className="pt-3">
                <legend>{props.nomTypeReference}</legend>
                <Row>
                    <Col className='pb-1 col-md-4 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersDejaClient.register[0]}>
                            {formulaireBaseValidations.servicesFinanciersDejaClient.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 d-flex '>
                        <ValidationField className="me-2" type='radio' label={'Oui'} value={'true'} register={props.register} errors={props.errors}
                            field={formulaireBaseValidations.servicesFinanciersDejaClient} disabled={props.isConsultation} />
                        <ValidationField type='radio' label={'Non'} value={'false'} register={props.register} errors={props.errors}
                            field={formulaireBaseValidations.servicesFinanciersDejaClient} disabled={props.isConsultation} />
                    </Col>

                    <Col className='pb-1 col-md-4 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersPolicePersonnelleOuFamilleImmediate.register[0]}>
                            {formulaireBaseValidations.servicesFinanciersPolicePersonnelleOuFamilleImmediate.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 d-flex '>
                        <ValidationField className="me-2" type='radio' label={'Oui'} value={'true'} register={props.register} errors={props.errors}
                            field={formulaireBaseValidations.servicesFinanciersPolicePersonnelleOuFamilleImmediate}
                            disabled={props.isConsultation} />
                        <ValidationField type='radio' label={'Non'} value={'false'} register={props.register} errors={props.errors}
                            field={formulaireBaseValidations.servicesFinanciersPolicePersonnelleOuFamilleImmediate}
                            disabled={props.isConsultation} />
                    </Col>

                </Row>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersCsfSouhaite.register[0]}>
                            {formulaireBaseValidations.servicesFinanciersCsfSouhaite.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='text' register={props.register} field={formulaireBaseValidations.servicesFinanciersCsfSouhaite} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersDateNaissance.register[0]} className="obligatoire">
                            {formulaireBaseValidations.servicesFinanciersDateNaissance.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='date' register={props.register} field={formulaireBaseValidations.servicesFinanciersDateNaissance} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersNombreEmployes.register[0]}>
                            {formulaireBaseValidations.servicesFinanciersNombreEmployes.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='number' register={props.register} field={formulaireBaseValidations.servicesFinanciersNombreEmployes} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersNombreDependants.register[0]}>
                            {formulaireBaseValidations.servicesFinanciersNombreDependants.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='number' register={props.register} field={formulaireBaseValidations.servicesFinanciersNombreDependants} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
                <Row as='fieldset' >
                    <Col className="col-3">
                        <Row className='pt-3'>
                            <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersPlanifications.register[0]}>
                                {formulaireBaseValidations.servicesFinanciersPlanifications.label}
                            </Form.Label>
                        </Row>
                        <Row>{getOptionsCheckbox(props.model?.optionsPlanifaction ?? [], 'planification', 'servicesFinanciersPlanifications')}</Row>
                    </Col>
                    <Col className="col-3">
                        <Row className='pt-3'>
                            <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersAssurances.register[0]}>
                                {formulaireBaseValidations.servicesFinanciersAssurances.label}
                            </Form.Label>
                        </Row>
                        <Row>{getOptionsCheckbox(props.model?.optionsAssurance ?? [], 'assurance', 'servicesFinanciersAssurances')}</Row>
                    </Col>
                    <Col className="col-3">
                        <Row className='pt-3'>
                            <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersEpargnes.register[0]}>
                                {formulaireBaseValidations.servicesFinanciersEpargnes.label}
                            </Form.Label>
                        </Row>
                        <Row>{getOptionsCheckbox(props.model?.optionsEpargne ?? [], 'epargne', 'servicesFinanciersEpargnes')}</Row>
                    </Col>
                    <Col className="col-3">
                        <Row className='pt-3'>
                            <Form.Label htmlFor={formulaireBaseValidations.servicesFinanciersAutres.register[0]}>
                                {formulaireBaseValidations.servicesFinanciersAutres.label}
                            </Form.Label>
                        </Row>
                        <Row>{getOptionsCheckbox(props.model?.optionsAutre ?? [], 'autre', 'servicesFinanciersAutres')}</Row>
                    </Col>
                </Row>
            </Row>
        </>
    );
}
