export const delaiMessageConfirmation = 2500;
export const ToastTimeout = 1000;
export const ShortToastTimeout = 500;
export const defaultPageSize = 50;

export const CodeReferenceAssuranceCollective = "ac";
export const CodeReferenceAssuranceEntreprise = "ae";
export const CodeReferenceAssuranceParticulier = "ap";
export const CodeReferenceAssuranceCime = "cime";
export const CodeReferenceServicesFinanccier = "sf";

export const StatutNonAffecte = 1;
export const StatutNonVendu = 4;
export const StatutVendu = 5;
export const StatutVenduPayeReferant = 6;