import { Alert, Button, Form, Modal } from "react-bootstrap";
import { clone } from "../../utils/cloneUtils";
import { SuiviModel, UsagerModel } from "../../services/types";
import ValidationField, { suiviValidations } from "../../services/validations";
import { SubmitHandler, useForm } from "react-hook-form";
import { getOptions } from "../../utils/optionsUtils";
import { useEffect } from "react";
import { ToastOptions } from "react-toastify";
import { ShortToastTimeout } from "../../utils/constantes";
import { getSuiviChangeAssignationReferenceIdCourtierId, getSuiviCourtierCodeFormulaire } from "../../services/services";
import { createSetError } from "../../utils/errorsUtils";
import { applyReferencesFilter } from "./suiviUI";

export default function ChangementAssignationUI(props: {
    model:SuiviModel,
    setModel: (model: SuiviModel) => void,
    showToast: (message: string, options: ToastOptions, closeOnly?: boolean) => void
}) {
    // Membres

    // Use
    const { register, reset, handleSubmit, formState: { errors } } = useForm<SuiviModel>();
    const setError = createSetError();

    // Méthodes
    useEffect(() => {
        suiviValidations.courtierIdSelectionne.register[1]['validate'] = val => 
            val === 0 ? suiviValidations.courtierIdSelectionne.customValidatorMessage : undefined;

        if (!props.model || !props.model.referenceSelectionnee)
            return;
        
        props.model.isCourtierEmpty = false;
        getSuiviCourtierCodeFormulaire(props.model.referenceSelectionnee?.codeFormulaire ?? '')
            .then(data => {
                if (!props.model || !props.model.referenceSelectionnee)
                    return;

                props.model.courtiers = data;
                if (!props.model?.referenceSelectionnee?.courtierId) {
                    props.model.courtierIdSelectionne = isCourtierExistsInList(data) ? props.model.courtierIdCourant ?? 0 : 0;
                }
                else {
                    props.model.courtierIdSelectionne = props.model?.referenceSelectionnee?.courtierId;
                }
                props.model.isCourtierEmpty = data.length === 0;
                props.setModel(props.model)
                reset(props.model);
            })
            .catch(setError);
        
    }, [props.model]);

    const save: SubmitHandler<SuiviModel> = async data => {
        getSuiviChangeAssignationReferenceIdCourtierId(props.model!.referenceSelectionnee!.id!, data.courtierIdSelectionne ?? 0)
        .then(data => {
            props.showToast('La référence a été assigné avec succès.', { type: 'success', autoClose: ShortToastTimeout });
            props.model.showChangementAssignation = false;
            const index = props.model.references?.findIndex(r => r.id == data.id);
            props.model!.references![index!] = data;
            const m = applyReferencesFilter(props.model);
            props.setModel(clone(m));
        })
        .catch(setError);
    };

    const liberer: SubmitHandler<SuiviModel> = async unusedData => {
        getSuiviChangeAssignationReferenceIdCourtierId(props.model!.referenceSelectionnee!.id!, 0)
        .then(data => {
            props.showToast('La référence à été libéré', { type: 'success', autoClose: ShortToastTimeout });
            props.model.showChangementAssignation = false;
            const index = props.model.references?.findIndex(r => r.id == data.id);
            props.model!.references![index!] = data;
            const m = applyReferencesFilter(props.model);
            props.setModel(clone(m));
        })
        .catch(setError);
    };

    const close = () => {
        props.model.showChangementAssignation = false;
        props.setModel(clone(props.model))
    }

    // Html
    const getCourtiers = () => {
        if (!props.model || !props.model.courtiers)
            return <></>;

        const firstItem = isCourtierExistsInList(props.model.courtiers) ? undefined : 'Sélectionner une personne...';
        return getOptions(props.model.courtiers, i => i.id!, i => i.nomPrenom!, firstItem)
    }

    const isCourtierExistsInList = (courtiers:UsagerModel[]) => {
        return courtiers.findIndex(c => c.id === props.model.courtierIdCourant) >= 0
    }

    return <Modal show={props.model.showChangementAssignation} backdrop="static" keyboard={false} centered={true}
                  onHide={close}>
        <Modal.Header closeButton>
            <Modal.Title>Assigner la référence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Label htmlFor={suiviValidations.courtierIdSelectionne.register[0]}>{suiviValidations.courtierIdSelectionne.label}</Form.Label>
            <ValidationField type='select' register={register} field={suiviValidations.courtierIdSelectionne} errors={errors}
                options={getCourtiers()} hidden={props.model.isCourtierEmpty} />
            <Alert variant='danger' hidden={!props.model.isCourtierEmpty}>Aucun courtier est assigné à ce type de référence, veuillez corriger la situation.</Alert>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="button" onClick={handleSubmit(save)} disabled={props.model.isCourtierEmpty}>
                    Assigner</Button>
            <Button variant="primary" type="button" onClick={handleSubmit(liberer)} disabled={props.model.isCourtierEmpty}
                    hidden={props.model.referenceSelectionnee?.statutId === 1}>Libérer</Button>
            <Button variant="secondary" type='button' 
                    onClick={close}>Annuler</Button>
        </Modal.Footer>
    </Modal>
}