import { UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import ValidationField, { formulaireBaseValidations } from "../../services/validations";
import { Col, Form, Row } from 'react-bootstrap';
import { AssuranceParticuliersModel } from "../../services/types";

export function ReferenceAssurancesPariculiersUI(props: {
    model?: AssuranceParticuliersModel | Partial<AssuranceParticuliersModel>,
    register: UseFormRegister<any>,
    errors: Partial<FieldErrorsImpl>,
    nomTypeReference:string|undefined,
    isConsultation?:boolean
}) {

    if (props.model === undefined)
        return <></>;

    return (
        <>
            <Row as='fieldset' className="pt-3">
                <legend>{props.nomTypeReference}</legend>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceParticuliersDateRenouvellementAuto.register[0]}>
                            {formulaireBaseValidations.assuranceParticuliersDateRenouvellementAuto.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='date' register={props.register} field={formulaireBaseValidations.assuranceParticuliersDateRenouvellementAuto} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceParticuliersAssureurActuelAuto.register[0]}>
                            {formulaireBaseValidations.assuranceParticuliersAssureurActuelAuto.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='text' register={props.register} field={formulaireBaseValidations.assuranceParticuliersAssureurActuelAuto} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceParticuliersDateRenouvellementHabitation.register[0]}>
                            {formulaireBaseValidations.assuranceParticuliersDateRenouvellementHabitation.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='date' register={props.register} field={formulaireBaseValidations.assuranceParticuliersDateRenouvellementHabitation} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceParticuliersAssureurActuelHabitation.register[0]}>
                            {formulaireBaseValidations.assuranceParticuliersAssureurActuelHabitation.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='text' register={props.register} field={formulaireBaseValidations.assuranceParticuliersAssureurActuelHabitation} 
                            errors={props.errors}  disabled={props.isConsultation}/>
                    </Col>
                </Row>
                <Row>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceParticuliersProvenanceReferance.register[0]}>
                            {formulaireBaseValidations.assuranceParticuliersProvenanceReferance.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='texte' register={props.register} field={formulaireBaseValidations.assuranceParticuliersProvenanceReferance} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                    <Col className='pb-1 col-md-2 col-12 pt-2'>
                        <Form.Label htmlFor={formulaireBaseValidations.assuranceParticuliersRaisonMagasinage.register[0]}>
                            {formulaireBaseValidations.assuranceParticuliersRaisonMagasinage.label}
                        </Form.Label>
                    </Col>
                    <Col className='pb-1 col-md-4 col-12'>
                        <ValidationField type='text' register={props.register} field={formulaireBaseValidations.assuranceParticuliersRaisonMagasinage} 
                            errors={props.errors} disabled={props.isConsultation} />
                    </Col>
                </Row>
            </Row>
        </>
    );
}
