import "./fallback.css"
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';

export const Fallback = (err:any) => {

    const [errorFormat, setErrorFormat] = useState(<></>);
    const [isDev, setIsDev] = useState(false);
    const [titlePrecision, setTitlePrecision] = useState('serveur');

    useEffect(() => {
        const isHttpError = err.error.status!;
        const isServerError = (isHttpError && err.error.status === 500) || err.error.status === 404
        let error:any;
        
        if (isServerError) {
            setTitlePrecision("serveur");
            error = err.error.response.data
            setErrorFormat(<>
                <div>Message&nbsp;:&nbsp;{error.Message}</div>
                <div>URL&nbsp;:&nbsp;{error.RequestURI}</div>
                <div>Statut&nbsp;:&nbsp;{error.StatusCode}</div>
                <div>Pile de appels&nbsp;:&nbsp;</div>
                <div className="ml-4">
                    {(error.StackTrace as string)?.split(/\n/).map(l => <div key={`ed_${uuidv4()}`}>{l}</div>)}
                </div>
            </>);
        }
        else {
            setTitlePrecision("client");
            setErrorFormat(<>
                <div>Message&nbsp;:&nbsp;{err.error.message}</div>
                {<div className="ml-4">
                    {(err.error.stack as string)?.split(/\n/).map(l => <div key={`ed_${uuidv4()}`}>{l}</div>)}
                </div>}
            </>);
        }
        const env = JSON.parse(sessionStorage.globalConfig).environnement.toLowerCase();
        setIsDev(env === "dev" || env ==="qa");

    }, [isDev]);

    if (err.error.message === 'noInternetConnection') {
        return (<>
            <Container className="p-md-0 p-lg-2">
                <div className="m-top-20 bandeau-erreur">
                    <i aria-hidden="true" className="rq-icons">&nbsp;</i>
                    <div className="alerte">
                        <h3>Un problème est survenu...</h3>
                        <pre>Veuillez réessayer plus tard...</pre>
                    </div>
                </div>
            </Container>
        </>)
    }

    if (isDev) {
        return (<>
            <Container className="p-md-0 p-lg-2 zen-bg">
                <div className="m-top-20 bandeau-erreur">
                    <i aria-hidden="true" className="rq-icons">&nbsp;</i>
                    <div className="alerte" style={{ overflowWrap: "break-word", width: "95%" }}>
                        <h3>Un problème est survenu (côté {titlePrecision})...</h3>
                        <pre>{errorFormat}</pre>
                    </div>
                </div>
            </Container>
        </>)
    }

    return (<>
        <Container className="p-md-0 p-lg-2">
            <div className="m-top-20 bandeau-erreur">
                <i aria-hidden="true" className="rq-icons">&nbsp;</i>
                <div className="alerte">
                    <h3>Un problème est survenu...</h3>
                    <pre>Vous pouvez revenir au menu</pre>
                </div>
            </div>
        </Container>
    </>)

}