import { UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import ValidationField, { formulaireBaseValidations } from "../../services/validations";
import { Col, Form, Row } from 'react-bootstrap';
import { AssuranceCollectiveModel } from "../../services/types";

export function ReferenceAssuranceCollectiveUI(props: {
    model?: AssuranceCollectiveModel | Partial<AssuranceCollectiveModel>,
    register: UseFormRegister<any>,
    errors: Partial<FieldErrorsImpl>,
    nomTypeReference:string|undefined,
    isConsultation?:boolean|undefined
}) {

  
    if (props.model === undefined)
        return <></>;

    return (
        <Row className="pt-4">
            <Col>
                <Row as="fieldset" className="pt-2">
                    <legend className='pb-2'>Assurance Collective</legend>
                    <Row className='pb-2'>
                        <Col className='col-md-4 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.assuranceCollectiveEstEnPlace.register[0]}>
                                {formulaireBaseValidations.assuranceCollectiveEstEnPlace.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-md-8 col-12 d-flex'>
                            <ValidationField className="me-2" type='radio' label={'Oui'} value={'true'} register={props.register} errors={props.errors}
                                field={formulaireBaseValidations.assuranceCollectiveEstEnPlace} disabled={props.isConsultation} />
                            <ValidationField type='radio' label={'Non'} value={'false'} register={props.register} errors={props.errors}
                                field={formulaireBaseValidations.assuranceCollectiveEstEnPlace} disabled={props.isConsultation} />
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col className='col-md-4 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.assuranceCollectiveAssureur.register[0]}>
                                {formulaireBaseValidations.assuranceCollectiveAssureur.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-md-8 col-12'>
                            <ValidationField type='text' register={props.register} field={formulaireBaseValidations.assuranceCollectiveAssureur} 
                                errors={props.errors} disabled={props.isConsultation} />
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col className='col-md-4 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.assuranceCollectiveDateRenouvellement.register[0]}>
                                {formulaireBaseValidations.assuranceCollectiveDateRenouvellement.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-md-8 col-12'>
                            <ValidationField type='date' register={props.register} field={formulaireBaseValidations.assuranceCollectiveDateRenouvellement} 
                                errors={props.errors} disabled={props.isConsultation} />
                        </Col>
                    </Row>
                </Row>
            </Col>
            <Col>
                <Row as="fieldset" className="pt-2">
                    <legend className='pb-2'>Régime de retraite</legend>
                    <Row className='pb-2'>
                        <Col className='col-md-4 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.assuranceCollectiveRegimeRetraiteEstEnPlace.register[0]}>
                                {formulaireBaseValidations.assuranceCollectiveRegimeRetraiteEstEnPlace.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-md-8 col-12 d-flex'>
                            <ValidationField className="me-2" type='radio' label={'Oui'} value={'true'} register={props.register} errors={props.errors}
                                field={formulaireBaseValidations.assuranceCollectiveRegimeRetraiteEstEnPlace} disabled={props.isConsultation} />
                            <ValidationField type='radio' label={'Non'} value={'false'} register={props.register} errors={props.errors}
                                field={formulaireBaseValidations.assuranceCollectiveRegimeRetraiteEstEnPlace} disabled={props.isConsultation} />
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col className='col-md-4 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.assuranceCollectiveRegimeRetraite.register[0]}>
                                {formulaireBaseValidations.assuranceCollectiveRegimeRetraite.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-md-8 col-12'>
                            <ValidationField type='text' register={props.register} field={formulaireBaseValidations.assuranceCollectiveRegimeRetraite} 
                                errors={props.errors} disabled={props.isConsultation} />
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col className='col-md-4 col-12 pt-2'>
                            <Form.Label htmlFor={formulaireBaseValidations.assuranceCollectiveDateRenouvellementRegimeRetraite.register[0]}>
                                {formulaireBaseValidations.assuranceCollectiveDateRenouvellementRegimeRetraite.label}
                            </Form.Label>
                        </Col>
                        <Col className='col-md-8 col-12'>
                            <ValidationField type='date' register={props.register} 
                                field={formulaireBaseValidations.assuranceCollectiveDateRenouvellementRegimeRetraite} 
                                errors={props.errors} disabled={props.isConsultation} />
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    );
}
