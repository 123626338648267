
import { useEffect, useState } from "react";
import { fetchAccessToken } from "../../services/config";
import { getConfigurations } from "../../services/services";
import { resetCurrentUsager, resetResponsable } from "../../utils/communUtils";

export const GetTokenUI = () => {

    // State
    const [token, setToken] = useState('');
    resetCurrentUsager();
    resetResponsable();
    useEffect(() => {
        getConfigurations().then(e => {
            e.environnement === 'dev' ? fetchAccessToken()!.then(t => { setToken(t as string) }) : null;
        })
    }, []);

    return (
        <div  style={{ overflowWrap: "break-word", width: "95%" }}>{ token }</div>
    )
}
