// ATTENTION : ce code est généré
import { ErrorMessage } from "@hookform/error-message";
import { MouseEventHandler, FormEventHandler, ChangeEventHandler } from "react";
import { Badge, Form } from "react-bootstrap";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";
import { FieldErrorsImpl, FieldValues, Path, RegisterOptions, UseFormClearErrors, UseFormRegister } from "react-hook-form";

export default function ValidationField<TFieldValues extends FieldValues>(props: {
    type: string,
    field: ValidationParameters,
    errors: Partial<FieldErrorsImpl>,
    register: UseFormRegister<TFieldValues>,
    className?: string | undefined,
    options?: JSX.Element | undefined,
    value?: string | number | string[] | undefined,
    isInline?: boolean | undefined,
    label?: string | undefined,
    showError?: boolean | undefined,
    controlName?: string | undefined,
    hidden?: boolean,
    disabled?: boolean,
    autoFocus?: boolean,
    rows?: number | undefined,
    inputMode?: "numeric" | "decimal" | undefined,
    max?: string | undefined,
    min?: string | undefined,
    readOnly?: boolean | undefined,
    onChange?: ChangeEventHandler<FieldValues>,
    cntrlClickHandle?: MouseEventHandler<HTMLInputElement> | undefined,
    onInputHandle?: FormEventHandler<FieldValues> | undefined;
    clearErrors?: UseFormClearErrors<TFieldValues>;
})
{
    const valRules = props.field.register[1] as Partial<FieldValues>;
    const maxLength = valRules['maxLength'] ? valRules['maxLength'] : -1;
    const cntrlName = (props.controlName ? props.controlName : props.field.register[0]) as Path<TFieldValues>;
    let isInvalid = props.errors[cntrlName] !== undefined;
    const showError = props.showError === undefined ? true : props.showError;

    if (cntrlName.indexOf(".") > -1)
    {
        const memberNames = cntrlName.split('.');
        const tmp = props.errors[memberNames[0]];
        for (const item in tmp)
        {
            if (item == memberNames[1])
            {
                isInvalid = true;
                break;
            }
        }
    }

    const stopComaAndDot = (e: any) =>
    {
        if (props.inputMode === 'numeric')
        {
            if (e.data === '.' || e.data === ',')
                e.preventDefault();
        }
    }

    let cntrl = <Form.Control type={props.type} maxLength={maxLength} onInput={props.onInputHandle} id={cntrlName}
        className={`${isInvalid ? 'is-invalid' : ''} ${props.className ?? ''}`} readOnly={props.readOnly ?? false}
        hidden={props.hidden} disabled={props.disabled} autoFocus={props.autoFocus ?? false}
        inputMode={props.inputMode != undefined ? `${props.inputMode}` : "text"} min={props.min}
        max={props.max === undefined && props.type.toLowerCase() === 'date' ? '9999-12-31' : props.max}
        onBeforeInput={stopComaAndDot}
        {...props.register(cntrlName, valRules)} />;

    if (props.type === 'select')
        cntrl = <Form.Select className={`${isInvalid ? "is-invalid" : ''}`} autoFocus={props.autoFocus ?? false}
            {...props.register(cntrlName, valRules)} hidden={props.hidden} disabled={props.disabled}
            onChange={e =>
            {
                if (props.onChange)
                    props.onChange(e);
                if (props.clearErrors)
                    props.clearErrors(cntrlName);
            }}>
            <>{props.options}</>
        </Form.Select>;

    if (props.type === 'radio' || props.type === 'checkbox')
    {
        cntrl = <Form.Check type={props.type as FormCheckType} label={props.label ?? props.field.label}
            className={`${isInvalid ? "is-invalid" : ''} ${props.className ?? ''}`} autoFocus={props.autoFocus ?? false}
            value={props.value} id={`${cntrlName}_${props.value}`} readOnly={props.readOnly ?? false}
            inline={props.isInline} hidden={props.hidden}
            {...props.register(cntrlName, valRules)}
            onClick={props.cntrlClickHandle} disabled={props.disabled} />;
    }

    if (props.type === 'textarea')
    {
        cntrl = <Form.Control className={`${isInvalid ? 'is-invalid' : ''} ${props.className ?? ''}`} as="textarea"
            rows={props.rows ?? 1} maxLength={maxLength} autoFocus={props.autoFocus ?? false} readOnly={props.readOnly ?? false}
            {...props.register(cntrlName, valRules)} disabled={props.disabled} />;
    }
    const htmlShowError = showError ? <ErrorMessage name={cntrlName}
        errors={props.errors} render={({ message }) => <Badge bg="danger float-start badge-error">{message}</Badge>} /> :
        <></>;
    return (
        <>
            {cntrl}
            {htmlShowError}
        </>
    );
}

type RegisterParameters = [string, RegisterOptions];

export type ValidationParameters = {
    label: string;
    register: RegisterParameters;
    customValidatorMessage?: string;
};

export type ValidationProperty = {
    [key: string]: ValidationParameters;
};

export const superviseurValidations: ValidationProperty = {
    prenom: {
        label: "Prénom",
        register: ["prenom", { required: "Veuillez saisir le prénom de l'usager." }]
    },
    nom: {
        label: "Nom",
        register: ["nom", { required: "Veuillez saisir le nom de l'usager." }]
    },
    roles: {
        label: "Rôle(s)",
        register: ["roles", { }]
    }
}

export const usagersValidations: ValidationProperty = {
    texteFiltre: {
        label: "Inscrire un nom, un prénom, un courriel, un superviseur, un département ou le nom d'une succursale à chercher",
        register: ["texteFiltre", { }]
    }
}

export const referenceValidations: ValidationProperty = {
    codeClient: {
        label: "Code du client",
        register: ["codeClient", { maxLength: 20 }]
    },
    numeroPolice: {
        label: "Numéro de police",
        register: ["numeroPolice", { maxLength: 20 }]
    },
    prime: {
        label: "Prime",
        register: ["prime", { setValueAs: (value: string) => parseFloat(value) }]
    }
}

export const suiviValidations: ValidationProperty = {
    texteFiltre: {
        label: "Inscrire un nom ou un statut",
        register: ["texteFiltre", { }]
    },
    statusSelected: {
        label: "Choisir les statuts",
        register: ["statusSelected", { required: "Veuillez inclure minimalement un statut." }]
    },
    courtierIdSelectionne: {
        label: "Sélectioner la personne à assigner à la référence",
        customValidatorMessage: "Veuillez sélectionner la personne.",
        register: ["courtierIdSelectionne", { setValueAs: (value: string) => parseInt(value) }]
    },
    statutIdSelectionne: {
        label: "Sélectioner le nouveau statut de la référence",
        register: ["statutIdSelectionne", { required: "Veuillez sélectionner le nouveau statut." }]
    }
}

export const formulaireBaseValidations: ValidationProperty = {
    type: {
        label: "Type",
        register: ["type", { }]
    },
    courrielReferenceur: {
        label: "Votre courriel PMT ROY",
        register: ["courrielReferenceur", { }]
    },
    nomReferenceur: {
        label: "Votre nom",
        register: ["nomReferenceur", { }]
    },
    nom: {
        label: "Nom",
        register: ["nom", { required: "Saisir le nom du client" }]
    },
    adresse: {
        label: "Adresse",
        register: ["adresse", { }]
    },
    appartementBureau: {
        label: "Appartement, bureau, etc.",
        register: ["appartementBureau", { }]
    },
    ville: {
        label: "Ville",
        customValidatorMessage: "Saisir la ville",
        register: ["ville", { }]
    },
    province: {
        label: "Province",
        register: ["province", { }]
    },
    codePostal: {
        label: "Code postal",
        register: ["codePostal", { }]
    },
    telephoneCellulaire: {
        label: "Téléphone - Cellulaire",
        register: ["telephoneCellulaire", { }]
    },
    telephoneResidence: {
        label: "Téléphone - Résidence",
        register: ["telephoneResidence", { }]
    },
    telephoneTravail: {
        label: "Téléphone - Travail",
        register: ["telephoneTravail", { }]
    },
    courriel: {
        label: "Courriel",
        register: ["courriel", { pattern: { value: /^[A-Z0-9+_.-]+@[A-Z0-9.-]+$/i, message: "Veuillez saisir un courriel valide." } }]
    },
    momentsContactFavorables: {
        label: "Moment le plus favorable pour vous joindre",
        register: ["momentsContactFavorables", { }]
    },
    typesTelephoneFavorables: {
        label: "Téléphone(s) pour vous joindre",
        register: ["typesTelephoneFavorables", { }]
    },
    autresInfos: {
        label: "Informations additionnelles du référent",
        register: ["autresInfos", { }]
    },
    assuranceEntrepriseDateRenouvellement: {
        label: "Date de renouvellement",
        register: ["assuranceEntreprise.dateRenouvellement", { }]
    },
    assuranceEntrepriseAssureurActuel: {
        label: "Assureur actuel",
        register: ["assuranceEntreprise.assureurActuel", { }]
    },
    assuranceEntrepriseProvenanceReference: {
        label: "Provenance de la référence",
        register: ["assuranceEntreprise.provenanceReference", { }]
    },
    assuranceEntrepriseRaisonMagasinage: {
        label: "Pourquoi le client masgasine-t-il?",
        register: ["assuranceEntreprise.raisonMagasinage", { }]
    },
    assuranceEntrepriseNomEntreprise: {
        label: "Nom de l'entreprise",
        register: ["assuranceEntreprise.nomEntreprise", { }]
    },
    assuranceEntreprise: {
        label: "assuranceEntreprise",
        register: ["assuranceEntreprise", { }]
    },
    assuranceParticuliersDateRenouvellementAuto: {
        label: "Date renouvellement Auto",
        register: ["assuranceParticuliers.dateRenouvellementAuto", { }]
    },
    assuranceParticuliersAssureurActuelAuto: {
        label: "Assureur actuel Auto",
        register: ["assuranceParticuliers.assureurActuelAuto", { }]
    },
    assuranceParticuliersDateRenouvellementHabitation: {
        label: "Date renouvellement Habitation",
        register: ["assuranceParticuliers.dateRenouvellementHabitation", { }]
    },
    assuranceParticuliersAssureurActuelHabitation: {
        label: "Assureur actuel Habitation",
        register: ["assuranceParticuliers.assureurActuelHabitation", { }]
    },
    assuranceParticuliersProvenanceReferance: {
        label: "Provenance de la référence",
        register: ["assuranceParticuliers.provenanceReferance", { }]
    },
    assuranceParticuliersRaisonMagasinage: {
        label: "Pourquoi le client masgasine-t-il?",
        register: ["assuranceParticuliers.raisonMagasinage", { }]
    },
    assuranceParticuliers: {
        label: "assuranceParticuliers",
        register: ["assuranceParticuliers", { }]
    },
    cimeAssuranceParticuliersNomProprietaireVehicule: {
        label: "Nom du propriétaire ou de la compagnie du véhicule",
        register: ["cimeAssuranceParticuliers.nomProprietaireVehicule", { }]
    },
    cimeAssuranceParticuliersDateNaissanceProprietaire: {
        label: "Si applicable, date de naissance du propriétaire",
        register: ["cimeAssuranceParticuliers.dateNaissanceProprietaire", { }]
    },
    cimeAssuranceParticuliersDatePrisePossesion: {
        label: "Date de prise de possession",
        register: ["cimeAssuranceParticuliers.datePrisePossesion", { }]
    },
    cimeAssuranceParticuliersAutresInformationsVehicule: {
        label: "Année, marque et modèle du véhicule",
        register: ["cimeAssuranceParticuliers.autresInformationsVehicule", { }]
    },
    cimeAssuranceParticuliersClasseVehicule: {
        label: "Classe du véhicule",
        register: ["cimeAssuranceParticuliers.classeVehicule", { }]
    },
    cimeAssuranceParticuliersEtatVehicule: {
        label: "État du véhicule",
        register: ["cimeAssuranceParticuliers.etatVehicule", { }]
    },
    cimeAssuranceParticuliersPrixVehicule: {
        label: "Prix du véhicule avant taxe incluant les accessoires",
        register: ["cimeAssuranceParticuliers.prixVehicule", { }]
    },
    cimeAssuranceParticuliers: {
        label: "cimeAssuranceParticuliers",
        register: ["cimeAssuranceParticuliers", { }]
    },
    servicesFinanciersDejaClient: {
        label: "Client déjà en services financiers chez PMT ROY?",
        register: ["servicesFinanciers.dejaClient", { }]
    },
    servicesFinanciersPolicePersonnelleOuFamilleImmediate: {
        label: "Police personnelle ou famille immédiate?",
        register: ["servicesFinanciers.policePersonnelleOuFamilleImmediate", { }]
    },
    servicesFinanciersCsfSouhaite: {
        label: "CSF souhaité?",
        register: ["servicesFinanciers.csfSouhaite", { }]
    },
    servicesFinanciersNombreEmployes: {
        label: "Nombre d'employés",
        register: ["servicesFinanciers.nombreEmployes", { }]
    },
    servicesFinanciersNombreDependants: {
        label: "Nombre de dépendants",
        register: ["servicesFinanciers.nombreDependants", { }]
    },
    servicesFinanciersPlanifications: {
        label: "Planification :",
        register: ["servicesFinanciers.planifications", { }]
    },
    servicesFinanciersAssurances: {
        label: "Assurance :",
        register: ["servicesFinanciers.assurances", { }]
    },
    servicesFinanciersEpargnes: {
        label: "Épargne :",
        register: ["servicesFinanciers.epargnes", { }]
    },
    servicesFinanciersAutres: {
        label: "Autre :",
        register: ["servicesFinanciers.autres", { }]
    },
    servicesFinanciersDateNaissance: {
        label: "Date de naissance :",
        customValidatorMessage: "Saisir la date de naissance",
        register: ["servicesFinanciers.dateNaissance", { }]
    },
    servicesFinanciers: {
        label: "servicesFinanciers",
        register: ["servicesFinanciers", { }]
    },
    assuranceCollectiveEstEnPlace: {
        label: "Régime en place?",
        register: ["assuranceCollective.estEnPlace", { }]
    },
    assuranceCollectiveAssureur: {
        label: "Assureur",
        register: ["assuranceCollective.assureur", { }]
    },
    assuranceCollectiveDateRenouvellement: {
        label: "Date renouvellement",
        register: ["assuranceCollective.dateRenouvellement", { }]
    },
    assuranceCollectiveRegimeRetraiteEstEnPlace: {
        label: "Régime en place?",
        register: ["assuranceCollective.regimeRetraiteEstEnPlace", { }]
    },
    assuranceCollectiveRegimeRetraite: {
        label: "Assureur",
        register: ["assuranceCollective.regimeRetraite", { }]
    },
    assuranceCollectiveDateRenouvellementRegimeRetraite: {
        label: "Date renouvellement",
        register: ["assuranceCollective.dateRenouvellementRegimeRetraite", { }]
    },
    assuranceCollective: {
        label: "assuranceCollective",
        register: ["assuranceCollective", { }]
    }
}

export const assuranceCollectiveValidations: ValidationProperty = {
    estEnPlace: {
        label: "Régime en place?",
        register: ["estEnPlace", { }]
    },
    assureur: {
        label: "Assureur",
        register: ["assureur", { }]
    },
    dateRenouvellement: {
        label: "Date renouvellement",
        register: ["dateRenouvellement", { }]
    },
    regimeRetraiteEstEnPlace: {
        label: "Régime en place?",
        register: ["regimeRetraiteEstEnPlace", { }]
    },
    regimeRetraite: {
        label: "Assureur",
        register: ["regimeRetraite", { }]
    },
    dateRenouvellementRegimeRetraite: {
        label: "Date renouvellement",
        register: ["dateRenouvellementRegimeRetraite", { }]
    }
}

export const assuranceEntrepriseValidations: ValidationProperty = {
    dateRenouvellement: {
        label: "Date de renouvellement",
        register: ["dateRenouvellement", { }]
    },
    assureurActuel: {
        label: "Assureur actuel",
        register: ["assureurActuel", { }]
    },
    provenanceReference: {
        label: "Provenance de la référence",
        register: ["provenanceReference", { }]
    },
    raisonMagasinage: {
        label: "Pourquoi le client masgasine-t-il?",
        register: ["raisonMagasinage", { }]
    },
    nomEntreprise: {
        label: "Nom de l'entreprise",
        register: ["nomEntreprise", { }]
    }
}

export const assuranceParticuliersValidations: ValidationProperty = {
    dateRenouvellementAuto: {
        label: "Date renouvellement Auto",
        register: ["dateRenouvellementAuto", { }]
    },
    assureurActuelAuto: {
        label: "Assureur actuel Auto",
        register: ["assureurActuelAuto", { }]
    },
    dateRenouvellementHabitation: {
        label: "Date renouvellement Habitation",
        register: ["dateRenouvellementHabitation", { }]
    },
    assureurActuelHabitation: {
        label: "Assureur actuel Habitation",
        register: ["assureurActuelHabitation", { }]
    },
    provenanceReferance: {
        label: "Provenance de la référence",
        register: ["provenanceReferance", { }]
    },
    raisonMagasinage: {
        label: "Pourquoi le client masgasine-t-il?",
        register: ["raisonMagasinage", { }]
    }
}

export const cimeAssuranceParticuliersValidations: ValidationProperty = {
    nomProprietaireVehicule: {
        label: "Nom du propriétaire ou de la compagnie du véhicule",
        register: ["nomProprietaireVehicule", { }]
    },
    dateNaissanceProprietaire: {
        label: "Si applicable, date de naissance du propriétaire",
        register: ["dateNaissanceProprietaire", { }]
    },
    datePrisePossesion: {
        label: "Date de prise de possession",
        register: ["datePrisePossesion", { }]
    },
    autresInformationsVehicule: {
        label: "Année, marque et modèle du véhicule",
        register: ["autresInformationsVehicule", { }]
    },
    classeVehicule: {
        label: "Classe du véhicule",
        register: ["classeVehicule", { }]
    },
    etatVehicule: {
        label: "État du véhicule",
        register: ["etatVehicule", { }]
    },
    prixVehicule: {
        label: "Prix du véhicule avant taxe incluant les accessoires",
        register: ["prixVehicule", { }]
    }
}

export const servicesFinanciersValidations: ValidationProperty = {
    dejaClient: {
        label: "Client déjà en services financiers chez PMT ROY?",
        register: ["dejaClient", { }]
    },
    policePersonnelleOuFamilleImmediate: {
        label: "Police personnelle ou famille immédiate?",
        register: ["policePersonnelleOuFamilleImmediate", { }]
    },
    csfSouhaite: {
        label: "CSF souhaité?",
        register: ["csfSouhaite", { }]
    },
    nombreEmployes: {
        label: "Nombre d'employés",
        register: ["nombreEmployes", { }]
    },
    nombreDependants: {
        label: "Nombre de dépendants",
        register: ["nombreDependants", { }]
    },
    planifications: {
        label: "Planification :",
        register: ["planifications", { }]
    },
    assurances: {
        label: "Assurance :",
        register: ["assurances", { }]
    },
    epargnes: {
        label: "Épargne :",
        register: ["epargnes", { }]
    },
    autres: {
        label: "Autre :",
        register: ["autres", { }]
    },
    dateNaissance: {
        label: "Date de naissance :",
        customValidatorMessage: "Saisir la date de naissance",
        register: ["dateNaissance", { }]
    }
}

export const destinataireValidations: ValidationProperty = {
    nomResponsable: {
        label: "Nom",
        register: ["nomResponsable", {
            required: "Veuillez saisir le nom du responsable.",
            maxLength: 50
        }]
    },
    courriel: {
        label: "Courriel",
        register: ["courriel", {
            required: "Veuillez saisir le courriel du destinataire.",
            pattern: { value: /.*\w@pmtroy.com$|.*\w@agencepmtroy.com$|.*\w@momentum-tech.ca$/, message: "Le couriel doit se terminer par @pmtroy.com ou @agencepmtroy.com" },
            maxLength: 100
        }]
    }
}

export const departementValidations: ValidationProperty = {
    nom: {
        label: "Nom",
        register: ["nom", { required: "Veuiller saisir le nom du département." }]
    }
}

export const succursaleValidations: ValidationProperty = {
    nom: {
        label: "Nom",
        register: ["nom", { required: "Veuiller saisir le nom du département." }]
    }
}

export const usagerValidations: ValidationProperty = {
    courriel: {
        label: "Courriel",
        register: ["courriel", {
            required: "Veuillez saisir le courriel de l'usager.",
            pattern: { value: /.*\w@pmtroy.com$|.*\w@agencepmtroy.com$|.*\w@agencepma.ca$|.*\w@ellipse.ca$|.*\w@agenceellipse.ca$|.*\w@cautionnement-expert.ca$|.*\w@lemoine-assurances.ca$|.*\w@sierraassurance.ca$|.*\w@manostrategies.ca$|.*\w@pma.ca$|.*\w@momentum-tech.ca$/, message: "Le domaine du courriel doit être l'un de ceux existant pour Ellipse." }
        }]
    },
    succursaleId: {
        label: "Succursale",
        register: ["succursaleId", {
            required: "Veuillez spécifier la succursale associée à l'usager.",
            min: { value: 1, message: "Veuillez spécifier la succursale associée à l'usager." },
            max: { value: 2147483647, message:"Veuillez spécifier la succursale associée à l'usager." },
            setValueAs: (value: string) => parseInt(value)
        }]
    },
    departementId: {
        label: "Département",
        register: ["departementId", {
            required: "Veuillez spécifier le département associé à l'usager.",
            min: { value: 1, message: "Veuillez spécifier le département associé à l'usager." },
            max: { value: 2147483647, message:"Veuillez spécifier le département associé à l'usager." },
            setValueAs: (value: string) => parseInt(value)
        }]
    },
    superviseurId: {
        label: "Superviseur",
        register: ["superviseurId", { setValueAs: (value: string) => parseInt(value) }]
    },
    nomSuccursale: {
        label: "Succursale",
        register: ["nomSuccursale", { }]
    },
    nomDepartement: {
        label: "Département",
        register: ["nomDepartement", { }]
    },
    prenom: {
        label: "Prénom",
        register: ["prenom", { required: "Veuillez saisir le prénom de l'usager." }]
    },
    nom: {
        label: "Nom",
        register: ["nom", { required: "Veuillez saisir le nom de l'usager." }]
    },
    roles: {
        label: "Rôle(s)",
        register: ["roles", { }]
    }
}

