import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import axios from 'axios';
import { GlobalVariables } from '../types';

const apiClient = axios.create({
  headers: {
    'Content-type': 'application/json'
  }
});

export function prepareConfig(setMsalInstance: (msalInstance: PublicClientApplication) => void) {
  if (sessionStorage.globalConfig === undefined) {
    const baseUrl = sessionStorage.getItem('environmentUrl');
    return apiClient
      .get<GlobalVariables>(baseUrl + '/api/Configurations')
      .then((response) => {
        const gcfg = response?.data;
        sessionStorage.setItem('globalConfig', JSON.stringify(gcfg));
        perpareMsalConfig(JSON.parse(sessionStorage.globalConfig));
        setMsalInstance(new PublicClientApplication(msalConfig));
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    perpareMsalConfig(JSON.parse(sessionStorage.globalConfig));
    setMsalInstance(new PublicClientApplication(msalConfig));
  }
}

function perpareMsalConfig(gcfg: GlobalVariables) {
  msalConfig.auth.clientId = gcfg.clientId!;
  msalConfig.auth.authority = gcfg.authority;
  msalConfig.auth.redirectUri = location.origin;
  loginRequest.scopes[0] = `api://${msalConfig.auth.clientId}/access_as_user`;
}

export const msalConfig: Configuration = {
  auth: {
    clientId: '',
    authority: '',
    redirectUri: ''
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

export const goToLogin = () => {
  const msal = new PublicClientApplication(msalConfig);
  msal.loginRedirect();
};
