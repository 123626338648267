import { Alert, Button, Modal } from "react-bootstrap";
import { clone } from "../../utils/cloneUtils";
import { SuiviModel } from "../../services/types";
import ValidationField, { suiviValidations } from "../../services/validations";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect } from "react";
import { ToastOptions } from "react-toastify";
import { ShortToastTimeout, StatutVendu, StatutVenduPayeReferant } from "../../utils/constantes";
import { getSuiviChangeStatutReferenceIdStatutId } from "../../services/services";
import { createSetError } from "../../utils/errorsUtils";
import { applyReferencesFilter } from "./suiviUI";

export default function ChangementStatutUI(props: {
    model:SuiviModel,
    setModel: (model: SuiviModel) => void,
    showToast: (message: string, options: ToastOptions, closeOnly?: boolean) => void
}) {
    // Membres

    // Use
    const { register, reset, handleSubmit, formState: { errors } } = useForm<SuiviModel>();
    const setError = createSetError();
    let countStatut = 0;

    // Méthodes
    useEffect(() => {
        if (!props.model || !props.model.statutIdSelectionne)
            return;
        
        reset(props.model);
        
    }, [props.model]);

    const save: SubmitHandler<SuiviModel> = async data => {
        getSuiviChangeStatutReferenceIdStatutId(props.model!.referenceSelectionnee!.id!, parseInt(data.statutIdSelectionne))
        .then(data => {
            props.showToast('Le statut a été changé avec succès.', { type: 'success', autoClose: ShortToastTimeout });
            props.model.showChangementStatut = false;
            const index = props.model.references?.findIndex(r => r.id == data.id);
            props.model!.references![index!] = data;
            const m = applyReferencesFilter(props.model);
            props.setModel(clone(m));
        })
        .catch(setError);
    };

    const canPassToStatutVendu = (statut:number) => {
        if (!props.model || !props.model.referenceSelectionnee)
            return false;
        
        const reference = props.model.referenceSelectionnee;
        const isValid = (statut === StatutVendu || statut === StatutVenduPayeReferant) &&
             ((reference.codeClient ?? '') === '' 
            ||(reference.numeroPolice ?? '') === ''
            ||(reference.prime ?? '') === '');
            
        return isValid;
    }

    // Html
    const statuts = props.model.statuts!.map(s => {
        const isDisabled = canPassToStatutVendu(s.id ?? 0);
        return <ValidationField type='radio' register={register} field={suiviValidations.statutIdSelectionne} showError={countStatut++ == 0}
                         errors={errors} value={s.id} label={s.nom} isInline={false} key={`statut_${s.id}`} 
                         disabled={isDisabled} />
    });

    return <Modal show={props.model.showChangementStatut} backdrop="static" keyboard={false} centered={true}
                  onHide={() => {props.model.showChangementStatut = false; props.setModel(clone(props.model))}}>
        <Modal.Header closeButton>
            <Modal.Title>Changer le statut</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Alert variant='warning' hidden={!canPassToStatutVendu(StatutVendu)}>
                Afin de changer pour le statut <span className="fw-bold">Vendue</span>, les informations du&nbsp;
                <span className="fw-bold">numéro de client</span>, <span className="fw-bold">numéro de police</span> et&nbsp;
                <span className="fw-bold">prime</span> doivent avoir été saisis.
            </Alert>
            {suiviValidations.statutIdSelectionne.label} :
            <div className="m-2">
                <>{statuts}</>
            </div>
            <ValidationField type='hidden' register={register} field={suiviValidations.statusSelected} errors={errors}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="button" onClick={handleSubmit(save)}>Ok</Button>
            <Button variant="secondary" type='button' 
                    onClick={() => {props.model.showChangementStatut = false; props.setModel(clone(props.model))}}>Annuler</Button>
        </Modal.Footer>
    </Modal>
}