import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAideAbout } from "../../../services/services";
import { AboutModel } from "../../../services/types";
import { v4 as uuidv4 } from 'uuid';
import './about.css';
import { createSetError } from "../../../utils/errorsUtils";

export default function AboutUI() {

    //Membres 

    // Use
    const setError = createSetError();

    // State
    const [model, setModel] = useState<AboutModel>()
    const [versionDisplay, setVersionDisplay] = useState<string>()

    // Méthodes
    useEffect(() => {
        getAideAbout()
            .then(data => {
                setModel(data);
                setVersionDisplay(data.versions![0].number);
            })
            .catch(setError);
    }, []);

    const header = <h1 className="pb-3">À propos de «&nbsp;Référencement par les employés&nbsp;»</h1>

    if (model === undefined)
        return <>{header}</>;

    const getVersions = () => {
        if (model == undefined || model.versions == undefined)
            return <></>;

        const html = model.versions.map(v => 
            <li key={`version_${v.number?.replace(/\\./g, '')}`} 
                className={`p-1 ps-3 fw-bold ${v.number === versionDisplay ? 'bg-primary text-white rounded-2' : ''}`}>
                {v.number !== versionDisplay 
                    ? <Link to={"#"} onClick={() => setVersionDisplay(v.number as string)}>Version {v.number}</Link> 
                    : <>Version {v.number}</>}
                <div className="version-date">{v.date}</div>
                
            </li>);
        return <u>{html}</u>
    }

    const getVersionDetails = () => {
        const versionDetails = model.versions?.find(v => v.number == versionDisplay);
        const html = versionDetails?.details?.map(d => <li key={`detail_${uuidv4()}`}>{d}</li>)
        return <ul className='mb-1'>{html}</ul>;
    }

    return <Container>
        {header}
        <Row>
            <Col className="col-3">
                {getVersions()}
            </Col>
            <Col className='bg-light rounded-2 p-2 me-2'>
                {getVersionDetails()}
            </Col>
        </Row>
    </Container>
}