import {Button, Modal} from "react-bootstrap";
import { FormulaireBaseModel } from "../../services/types";
import { clone } from "../../utils/cloneUtils";

export default function AlertNomReferenceurInvalideUI(props: {model: FormulaireBaseModel|undefined, setModel: (model:FormulaireBaseModel) => void }) {

    const handleClose = () =>
    {
        if (!props.model)
            return;

        props.model.showCourrielInexistant = false;
        props.setModel(clone(props.model));
    }

    return (
        <>
            <Modal show={props?.model?.showCourrielInexistant} onHide={handleClose} backdrop="static"
                   keyboard={false} className="create-candidate" >
                <Modal.Header closeLabel={''} closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Le nom du référant est invalide.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}