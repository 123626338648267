import { Container } from "react-bootstrap";
import { getCurrentAccount } from "../../services/Authentification/AuthFunction";


export default function ForbiUI() {

    // Membres
    const account = getCurrentAccount();

    // Html
    return <Container>
        <h1 className='text-danger'>Oups...</h1>
        <h2 className='text-danger'>Vous n'avez pas accès à la ressource demandée.</h2>
        <p>
            Bonjour M. {account?.name}, votre niveau d'accès ne permet pas d'accéder à la fonctionnalité demandée. Si vous croyez 
            pouvoir accéder légitimement à cette fonctionnalité, veuillez contacter le support à l'adresse suivante&nbsp;:&nbsp;
            <a href='mailto:sosti@pmtroy.com'>sosti@pmtroy.com</a>
        </p>
    </Container>
}